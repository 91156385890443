import React, { FC } from "react";
import {
  QuizActiveMissingWord,
  QuizIntro,
  QuizMCQ,
  QuizShortAnswer,
  QuizCorrectMistake,
  QuizPassiveMissingWord,
  QuizComplete,
} from "./";
import { useQuizContext } from "../../contexts";

export const QuizBodyCard: FC = () => {
  const { currentQuestionIndex, quizQuestions } = useQuizContext();

  if (
    !quizQuestions ||
    currentQuestionIndex === -1 ||
    quizQuestions.length !== 10
  ) {
    return <QuizIntro />;
  }

  if (currentQuestionIndex >= quizQuestions.length) {
    return <QuizComplete />;
  }

  const currentQuestion = quizQuestions[currentQuestionIndex];

  switch (currentQuestion.type) {
    case "MultipleChoice":
      return <QuizMCQ />;
    case "ShortAnswer":
      return <QuizShortAnswer />;
    case "ActiveMissingWord":
      return <QuizActiveMissingWord />;
    case "CorrectMistake":
      return <QuizCorrectMistake />;
    case "PassiveMissingWord":
      return <QuizPassiveMissingWord />;
    default:
      return <QuizIntro />;
  }
};

import React, { FC } from "react";
import classes from "./QuizStreak.module.scss";
import { Typography } from "../Typography/Typography";
import { Chip } from "../Chip/Chip";

function countConsecutiveOnes(array: Array<number>) {
  let consecutiveOnes = 0;

  for (let i = 0; i < array.length; i++) {
    if (array[i] === 1) {
      consecutiveOnes++;
    } else if (array[i] === 2) {
      // ignore flagged questions
      continue;
    } else if (array[i] === 0) {
      consecutiveOnes = 0;
    } else if (array[i] === null) {
      break;
    }
  }

  return consecutiveOnes;
}

function countNotNegative(array: Array<number>) {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== -1) {
      count++;
    }
  }
  return count;
}

function getSubtitleText(currentQuestionIndex: number, answers: number[]) {
  if (currentQuestionIndex === -1) {
    return (
      <Typography textAlign="start" bold>
        Get Ready!
      </Typography>
    );
  }

  if (currentQuestionIndex === 10) {
    // quiz completed
    return (
      <Typography textAlign="start" bold>
        Quiz Complete!
      </Typography>
    );
  }

  if (countNotNegative(answers) === 0 && currentQuestionIndex === 0) {
    return (
      <Typography textAlign="start" bold>
        Question 1/10
      </Typography>
    );
  }

  return countConsecutiveOnes(answers) > 1 ? (
    <Chip variant="Mastered" icon={false}>{`${countConsecutiveOnes(
      answers
    )} Question Streak!`}</Chip>
  ) : (
    <div className={classes.streakText}>
      <Typography textAlign="start" bold>{`Question ${(
        currentQuestionIndex + 1
      ).toString()}/${answers.length}`}</Typography>
    </div>
  );
}

interface QuizStreakProps {
  currentQuestionIndex: number;
  answers: number[];
}

export const QuizStreak: FC<QuizStreakProps> = ({
  answers,
  currentQuestionIndex,
}) => {
  return (
    <div className={classes.container}>
      {getSubtitleText(currentQuestionIndex, answers)}

      <div className={classes.streakContainer}>
        {answers.map((answer, index) => {
          return (
            <div
              key={index}
              className={[
                classes.streakItem,
                answer === 1
                  ? classes.correct
                  : answer === 0
                  ? classes.incorrect
                  : answer === 2 && classes.flagged,
              ].join(" ")}
            />
          );
        })}
      </div>
    </div>
  );
};

import React from "react";
import classes from "./QuizIntro.module.scss";
import { Typography } from "../../Typography/Typography";
import { Chip, Divider } from "../../";
import { useQuizContext } from "../../../contexts";

export const QuizIntro = () => {
  const { quizQuestions, isLoading } = useQuizContext();

  if (isLoading) {
    return (
      <div>
        <Typography variant="body1">Loading Quiz</Typography>
      </div>
    );
  }

  if (quizQuestions?.length !== 10 || !quizQuestions) {
    return (
      <div>
        <Typography variant="body1">
          Looks like there are no questions for this topic yet. Check back soon
          and we'll have some ready for you!
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.listContainer}>
      <ul>
        <li>
          <Typography variant="body1">
            You will help Dino by answering 10 questions about the chosen topic.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Dino will ask you the questions, so pay attention to the dialogue
            box.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Do your best to answer - Dino will explain afterwards if you need
            any help!
          </Typography>
        </li>
      </ul>
      <Divider onCard />
      <div className={classes.statusContainer}>
        <div>
          <Chip variant="Mastered" />{" "}
          <Typography variant="body1">Score &gt;90%</Typography>
        </div>
        <div>
          <Chip variant="Complete" />{" "}
          <Typography variant="body1">Score &gt;70%</Typography>
        </div>
        <div>
          <Chip variant="In Progress" />{" "}
          <Typography variant="body1">Other Attempts</Typography>
        </div>
      </div>
    </div>
  );
};

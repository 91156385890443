import React, { FC } from "react";
import { useGetFeedbackGiven } from "../../hooks";
import { LoadingLayout, SurveyLayout } from "../../layouts";

interface SurveyGuardProps {
  children: React.ReactNode;
}

export const SurveyGuard: FC<SurveyGuardProps> = ({ children }) => {
  const { data: feedbackGiven, isLoading, error } = useGetFeedbackGiven();

  if (isLoading) {
    return <LoadingLayout />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!feedbackGiven) {
    return (
      <>
        {children}
        <SurveyLayout />
      </>
    );
  }

  return <>{children}</>;
};

import React from "react";
import { Typography } from "../../";
import classes from "../QuizDialogueBase.module.scss";

export const QuizActiveMissingWord = () => {
  return (
    <div className={classes.dialogBox}>
      <Typography fullWidth variant="body1">
        My teacher changed lecture slides too quickly! Can you help me finish my
        notes?
      </Typography>
    </div>
  );
};

import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { useGetAuth } from "../../hooks";
import { LoadingLayout } from "../../layouts";

interface AnonymousGuardProps {
  children: React.ReactNode;
}

export const AnonymousGuard: FC<AnonymousGuardProps> = ({ children }) => {
  const { data: user, isLoading, error } = useGetAuth();

  if (isLoading) {
    return <LoadingLayout />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (user) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

import React, { FC } from "react";
import classes from "./CourseCard.module.scss";
import { Card, CircularProgress, Typography, VerticalAccent } from "..";
import { motion } from "framer-motion";
import { useBreakpoint } from "../../hooks/useBreakpoint";

interface CourseCardProps {
  onClick: () => void;
  title: string;
  lastStudied: string;
  progress: number;
  numberOfModules: number;
  index: number;
}

export const CourseCard: FC<CourseCardProps> = ({
  onClick,
  title,
  lastStudied,
  progress,
  numberOfModules,
  index,
}) => {
  const breakpoint = useBreakpoint();
  const getAccentColourIndex = (index: number) => {
    // make indices repeat as 1 - 4
    return (index % 4) + 1;
  };

  return (
    <motion.a
      className={classes.cardContainer}
      onClick={onClick}
      whileHover={{
        scale: 1.005,
        boxShadow: "0px 20px 30px 0px rgba(169, 177, 206, 0.08)",
        transition: { duration: 0.1 },
      }}
      whileTap={{
        scale: 0.995,
        boxShadow: "none",
        transition: { duration: 0.02 },
      }}
    >
      <Card>
        <div className={classes.cardContent}>
          <div className={classes.title}>
            <VerticalAccent variant={getAccentColourIndex(index)} />
            <div className={classes.titleTextContainer}>
              <Typography variant="h2">{title}</Typography>
              <Typography>
                {lastStudied ? `Last Studied: ${lastStudied}` : "New Course"}
              </Typography>
            </div>
          </div>

          <div className={classes.progress}>
            {breakpoint !== "xs" && (
              <Typography primary={false}>
                {progress.toString()}/{numberOfModules.toString()}
              </Typography>
            )}
            <CircularProgress
              progress={
                isNaN(progress / numberOfModules)
                  ? 1
                  : progress / numberOfModules
              }
            />
          </div>
        </div>
      </Card>
    </motion.a>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Get Random Username:
 * @returns string | null
 */
export const getRandomUsername = async (): Promise<string | null> => {
  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/auth/random-username`
    );
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

import React from "react";
import { Typography } from "../../";
import classes from "../QuizDialogueBase.module.scss";

export const QuizCorrectMistake = () => {
  return (
    <div className={classes.dialogBox}>
      <Typography fullWidth variant="body1">
        I think I made a mistake when writing my notes. Can you help me find it?
      </Typography>
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { Course } from "../../typed";
import { getCourses } from "../../api";

export const useGetCourses = () => {
  return useQuery<Course[] | undefined, Error>({
    queryKey: ["courses"],
    queryFn: () => getCourses(),
  });
};

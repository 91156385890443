import React, { FC } from "react";
import classes from "./TopicsTable.module.scss";
import { TopicRow } from "../TopicRow/TopicRow";
import { motion } from "framer-motion";
import { Typography } from "../Typography/Typography";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { TopicRowSkeleton } from "../TopicRowSkeleton/TopicRowSkeleton";
import { useGetCourseModuleTopics } from "../../hooks";

interface TopicsTableProps {
  expanded: boolean;
  numberOfTopics: number;
  course_id: number | undefined;
  module_id: number | undefined;
}

export const TopicsTable: FC<TopicsTableProps> = ({
  numberOfTopics,
  expanded,
  course_id,
  module_id,
}) => {
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();
  const {
    data: topics,
    isLoading,
    error,
  } = useGetCourseModuleTopics(course_id, module_id);

  const handleOnClickTopic = (topic_id: number) => {
    navigate(`/course/${course_id}/${module_id}/${topic_id}/quiz`);
  };

  return (
    <motion.div
      className={classes.tableContainer}
      layout
      initial={{ padding: "0px 25px" }}
      animate={{
        padding: expanded ? "25px 25px" : "0px 25px",
      }}
      transition={{ duration: 0.2 }}
      style={{ overflow: "hidden" }}
    >
      <table>
        <thead>
          <tr className={classes.tableHeader}>
            <th>
              <Typography bold>Topic</Typography>
            </th>
            <th>
              <Typography bold>Status</Typography>
            </th>
            {breakpoint !== "xs" && breakpoint !== "sm" && (
              <th>
                <Typography bold textAlign="center">
                  Attempts
                </Typography>
              </th>
            )}
            {breakpoint !== "xs" && (
              <th>
                <Typography bold textAlign="center">
                  Best
                </Typography>
              </th>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? Array.from({ length: numberOfTopics }).map((_, index) => {
                return <TopicRowSkeleton key={index} />;
              })
            : topics &&
              topics.map((topic, index) => {
                return (
                  <TopicRow
                    key={index}
                    topic={topic}
                    onclick={() => handleOnClickTopic(topic.topic_id)}
                  />
                );
              })}
        </tbody>
      </table>
    </motion.div>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { User } from "../../typed";

/**
 * Sign Up:
 * @param signUpData { username: string, password: string }
 * @returns SignUpResponse { message: string, user: User } | null
 */
export const postSignUp = async (
  username: string,
  password: string
): Promise<User | string> => {
  try {
    const { data, status } = await axios.post(
      `${apiConstants.base_url}/auth/signup`,
      { username: username, password: password, role: "Student" }
    );
    if (status === 201) {
      return data;
    }
    return "Unable to sign up. Please try again later.";
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        return "Sorry! Someone just took that username. Please try another one.";
      } else if (error.response?.status === 401) {
        return "Unable to sign up. Please try again later.";
      }
    }
    return "Unable to sign up. Please try again later.";
  }
};

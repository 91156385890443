import React, { useState } from "react";
import classes from "./EnrolLayout.module.scss";
import { Button, Divider, Typography, VerticalAccent } from "../../components";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow_right.svg";
import { useNavigate, useParams } from "react-router-dom";
import { postCourseEnrol } from "../../api";
import { useGetCourseUserEnrolled } from "../../hooks";

export const EnrolLayout = () => {
  const { course_name } = useParams();
  const course_name_stripped = course_name?.replace(/-/g, " ");
  const { data: userAlreadyEnrolled } = useGetCourseUserEnrolled(course_name);

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (course_name_stripped === undefined) {
      return;
    }
    setIsSubmitting(true);
    // Enrol user in the course
    try {
      const enrolResponse = await postCourseEnrol(course_name_stripped);
      if (enrolResponse !== undefined) {
        navigate("/");
      }
    } catch (error) {}
    setIsSubmitting(false);
  };

  return (
    <div className={classes.signUpContainer}>
      <div className={classes.titleContainer}>
        <VerticalAccent />
        <div>
          <Typography variant="h1">
            {course_name_stripped ? course_name_stripped : " "}
          </Typography>
          <Typography>Enrol in a new course!</Typography>
        </div>
      </div>
      <div>
        <Divider />
        <Typography>
          By enrolling, you are agreeing to the collection of your anonymous
          data for research purposes. For details on the data being collected,
          please visit{" "}
          <a
            style={{ display: "inline-block" }}
            href="/research-data-collection"
          >
            research data collection
          </a>
        </Typography>

        <Divider />
        {userAlreadyEnrolled === undefined && (
          <Typography variant="error">
            An error occurred while checking your enrolment status. Please try
            again later.
          </Typography>
        )}
      </div>
      <Button
        onClick={handleSubmit}
        icon={<RightArrow />}
        disabled={isSubmitting || userAlreadyEnrolled === undefined}
      >
        Let's go!
      </Button>
    </div>
  );
};

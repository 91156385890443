import React from "react";
import { Typography } from "../../";
import classes from "../QuizDialogueBase.module.scss";

export const QuizPassiveMissingWord = () => {
  return (
    <div className={classes.dialogBox}>
      <Typography fullWidth variant="body1">
        I am having trouble remembering the missing section in my notes. Can you
        help me?
      </Typography>
    </div>
  );
};

import React from "react";
import { Chip, Typography } from "../../";
import classes from "../QuizDialogueBase.module.scss";
import { useQuizContext } from "../../../contexts";
import Lottie from "lottie-react";
import loadingLottie from "../../../assets/lotties/loading-green.json";

export const QuizAnswer = () => {
  const { isAnswerCorrect, answerExplanation } = useQuizContext();
  // TODO: get answer explanation if required

  return (
    <div
      className={[
        classes.dialogBoxAnswer,
        answerExplanation !== undefined &&
          isAnswerCorrect === false &&
          classes.explanation,
      ].join(" ")}
    >
      {isAnswerCorrect && (
        <Chip variant="Mastered" icon={false}>
          +10 XP
        </Chip>
      )}
      <div
        style={{
          paddingBottom: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {isAnswerCorrect === undefined && (
          <div
            style={{
              maxHeight: "30px",
              maxWidth: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie
              animationData={loadingLottie}
              style={{
                maxHeight: "80px",
                maxWidth: "300px",
                objectFit: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>
        )}
        <Typography textAlign="start" variant="body1">
          {isAnswerCorrect && <strong>That's Correct! Nice work!</strong>}
          {isAnswerCorrect === false && answerExplanation !== undefined && (
            <>
              <strong>Incorrect: </strong>
              {answerExplanation}
            </>
          )}
          {isAnswerCorrect === undefined && (
            <strong>Hmm, let me think...</strong>
          )}
        </Typography>
      </div>
    </div>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { LeaderboardItem } from "../../typed";

/**
 * Get Course Leaderboard:
 * @param course_id
 * @returns Leaderboard[] | null
 */
export const getCourseLeaderboard = async (
  course_id: number | undefined
): Promise<LeaderboardItem[]> => {
  if (!course_id) {
    return [];
  }

  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/courses/${course_id}/leaderboard`
    );
    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

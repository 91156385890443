import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { User } from "../../typed";

export const postSignIn = async (
  username: string,
  password: string
): Promise<User | string> => {
  try {
    const { data, status } = await axios.post(
      `${apiConstants.base_url}/auth/signin`,
      { username: username, password: password }
    );
    if (status === 201) {
      return data;
    }
    return "An unknown error occurred";
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return "User not found";
      } else if (error.response?.status === 401) {
        return "Invalid password";
      }
    }
    return "An unknown error occurred";
  }
};

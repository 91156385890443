import React, { FC } from "react";
import classes from "./MultipleChoiceGroup.module.scss";
import { MCQOption } from "../MCQOption/MCQOption";

interface MultipleChoiceGroupProps {
  options: string[];
  selected: number;
  setSelected: (index: number) => void;
}

export const MultipleChoiceGroup: FC<MultipleChoiceGroupProps> = ({
  options,
  selected,
  setSelected,
}) => {
  const handleOnClick = (index: number) => {
    setSelected(index);
  };

  return (
    <div className={classes.container}>
      {options.map((option, index) => {
        return (
          <MCQOption
            key={index}
            selected={selected === index}
            text={option}
            disabled={false}
            onClick={() => handleOnClick(index)}
          />
        );
      })}
    </div>
  );
};

import React, { FC } from "react";
import classes from "./Checkbox.module.scss";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark.svg";
import { motion } from "framer-motion";

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({ checked, disabled = false }) => {
  return (
    <motion.div
      className={[
        classes.checkbox,
        disabled && classes.disabled,
        checked && classes.checked,
      ].join(" ")}
      animate={{ scale: checked ? 1.05 : 1 }}
      transition={{ type: "spring", bounce: 0.5, duration: 0.2 }}
    >
      {checked && (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ type: "spring", bounce: 0.5, duration: 0.6 }}
        >
          <Checkmark />
        </motion.div>
      )}
    </motion.div>
  );
};

import {
  MultipleChoice,
  ShortAnswer,
  ActiveMissingWord,
  CorrectMistake,
  VerifyCorrectness,
  PassiveMissingWord,
  QuizQuestion,
} from "../typed/quizTypes";

/** Takes a question and returns it content as a given question type
 * @param question - The question to get the content of
 * @returns The content of the question as a specific type
 */
const getQuestionContentTyped = (
  question: QuizQuestion
):
  | MultipleChoice
  | ShortAnswer
  | ActiveMissingWord
  | CorrectMistake
  | VerifyCorrectness
  | PassiveMissingWord
  | null => {
  switch (question.type) {
    case "MultipleChoice":
      return question.content as MultipleChoice;
    case "ShortAnswer":
      return question.content as ShortAnswer;
    case "ActiveMissingWord":
      return question.content as ActiveMissingWord;
    case "CorrectMistake":
      return question.content as CorrectMistake;
    case "VerifyCorrectness":
      return question.content as VerifyCorrectness;
    case "PassiveMissingWord":
      return question.content as PassiveMissingWord;
    default:
      return null;
  }
};

export default getQuestionContentTyped;

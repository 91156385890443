import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Post Streak: (add today to streak)
 * @returns Boolean | null
 */
export const postStreak = async (): Promise<boolean | null> => {
  try {
    const localDate = new Date();
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const { status } = await axios.post(
      `${apiConstants.base_url}/user/streak`,
      { date: formattedDate }
    );

    if (status === 200) {
      return true;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

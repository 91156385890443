import React from "react";
import { Typography } from "../../Typography/Typography";
import classes from "./QuizIntro.module.scss";
import { useGetTopicName } from "../../../hooks";
import { useParams } from "react-router-dom";
import { useQuizContext } from "../../../contexts";

export const QuizIntro = () => {
  const { course_id, module_id, topic_id } = useParams();
  const course_id_number = course_id ? parseInt(course_id) : undefined;
  const module_id_number = module_id ? parseInt(module_id) : undefined;
  const topic_id_number = topic_id ? parseInt(topic_id) : undefined;
  const { quizQuestions, isLoading } = useQuizContext();
  const { data: topicName } = useGetTopicName(
    course_id_number,
    module_id_number,
    topic_id_number
  );
  return (
    <div className={classes.dialogBox}>
      {isLoading ? (
        <div>
          <Typography variant="h2">Loading...</Typography>
        </div>
      ) : quizQuestions?.length !== 10 || !quizQuestions ? (
        <div>
          <Typography variant="h2">Uh oh!</Typography>
          <Typography variant="body1">
            I can't think of any questions for this topic right now.
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h2">{topicName}</Typography>
          <Typography variant="body1">
            Hi there! I'm Dino and I'm also studying “{topicName}”. Let's help
            each other out and learn together!
          </Typography>
        </div>
      )}
    </div>
  );
};

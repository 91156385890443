import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

export const postSignOut = async (): Promise<boolean | undefined> => {
  try {
    const { status } = await axios.post(
      `${apiConstants.base_url}/auth/signout`
    );
    if (status === 200) {
      return true;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Enrol in Course:
 * @param course_id
 * @returns EnrolResponse { message: string, enrolment: Enrolment } | null
 */
export const postCourseEnrol = async (
  course_name: string
): Promise<number | undefined> => {
  try {
    const { data, status } = await axios.post(
      `${apiConstants.base_url}/courses/${course_name}/enrol`
    );
    if (status === 201) {
      return data.course_id;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

import { useQuery } from "@tanstack/react-query";
import { Topic } from "../../typed";
import { getCourseModuleTopics } from "../../api";

export const useGetCourseModuleTopics = (
  course_id: number | undefined,
  module_id: number | undefined
) => {
  return useQuery<Topic[] | null, Error>({
    queryKey: ["topics", course_id, module_id],
    queryFn: () => getCourseModuleTopics(course_id, module_id),
    enabled: course_id !== undefined && module_id !== undefined,
  });
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Check if course with course_name exists:
 * @param course_name
 * @returns Boolean
 */
export const getCourseExists = async (
  course_name: string | undefined
): Promise<number | undefined> => {
  if (!course_name) {
    return undefined;
  }

  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/courses/${course_name}/exists`
    );
    if (status === 200) {
      return data.course_id;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

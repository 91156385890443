import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Post Quiz Complete:
 * @param course_id
 * @param module_id
 * @param topic_id
 * @param quiz_score
 * @param points_earned
 * @returns Boolean
 */
export const postQuizComplete = async (
  course_id: number,
  module_id: number,
  topic_id: number,
  quiz_score: number, // score (0-1 decimal) of the quiz
  points_earned: number // total points earned in the quiz
): Promise<boolean> => {
  try {
    const payload = {
      course_id,
      module_id,
      topic_id,
      quiz_score,
      points_earned,
    };
    const { status } = await axios.post(
      `${apiConstants.base_url}/quiz/quiz-complete`,
      payload
    );
    if (status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error completing quiz:", error);
    return false;
  }
};

import React, { useEffect, useState } from "react";
import classes from "./SignInLayout.module.scss";
import {
  Button,
  Divider,
  InputField,
  Typography,
  VerticalAccent,
} from "../../components";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow_right.svg";
import { useNavigate } from "react-router-dom";
import { postSignIn } from "../../api";
import { useQueryClient } from "@tanstack/react-query";

export const SignInLayout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [username, setUsername] = useState<string>(() => {
    return localStorage.getItem("username") || "";
  });
  const [password, setPassword] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    setErrorMessage("");
    setIsSubmitting(true);
    if (username && password) {
      try {
        const user = await postSignIn(username, password);
        if (typeof user === "string") {
          setErrorMessage(user);
        } else if (user !== undefined) {
          // Enroll user in the course
          queryClient.invalidateQueries({ queryKey: ["auth"] });
          navigate("/", { replace: true });
        }
      } catch (error) {
        console.error("Error signing in:", error);
      }
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (username && password) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [username, password]);

  return (
    <div className={classes.signUpContainer}>
      <div className={classes.titleContainer}>
        <VerticalAccent />
        <div>
          <Typography variant="h1">Welcome back</Typography>
          <Typography>Keep up the good work studying!</Typography>
        </div>
      </div>
      <Divider />
      <div className={classes.inputContainer}>
        <Typography
          primary={false}
          tooltip="This was your anonymous username when you signed up"
        >
          Username
        </Typography>
        <InputField
          value={username}
          onChange={(e: any) => {
            setUsername(e.target.value);
          }}
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography primary={false}>Password</Typography>
        <InputField
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value);
          }}
          type="password"
        />
      </div>
      {errorMessage !== "" && (
        <Typography variant="error">{errorMessage}</Typography>
      )}
      <Divider />
      <Button
        onClick={handleSubmit}
        icon={<RightArrow />}
        disabled={isSubmitDisabled || isSubmitting}
      >
        Let's go!
      </Button>
    </div>
  );
};

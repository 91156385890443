import React, { FC } from "react";
import classes from "./ChipSelectable.module.scss";
import { Typography } from "../Typography/Typography";

interface ChipSelectableProps {
  selected: boolean;
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

export const ChipSelectable: FC<ChipSelectableProps> = ({
  selected,
  onClick,
  disabled,
  children,
}) => {
  return (
    <button
      className={[classes.chipContainer, selected && classes.selected].join(
        " "
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography>{children}</Typography>
    </button>
  );
};

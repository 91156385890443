import React, { useState } from "react";
import classes from "./Typography.module.scss";
import { ReactComponent as InfoIcon } from "../../assets/icons/info_icon.svg";
import { Tooltip } from "../";

interface TypographyProps {
  bold?: boolean;
  primary?: boolean;
  variant?: "h1" | "h2" | "body1" | "body2" | "button" | "error";
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  textAlign?: "start" | "center" | "end";
  fullWidth?: boolean;
  tooltip?: string;
  children: React.ReactNode;
}

export const Typography: React.FC<TypographyProps> = ({
  bold = false,
  primary = true,
  variant = "body2",
  component: Component = "p",
  fullWidth = false,
  textAlign = "start",
  tooltip = undefined,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <Component
        className={[
          classes.typography,
          classes[variant],
          bold && classes["bold"],
          classes[textAlign],
          !primary && classes["secondary"],
          fullWidth && classes["fullWidth"],
        ].join(" ")}
      >
        {children}
        {tooltip !== undefined && (
          <span
            className={[
              classes.infoIcon,
              !primary && classes["secondary"],
            ].join(" ")}
          >
            <InfoIcon
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
            {showTooltip && <Tooltip>{tooltip}</Tooltip>}
          </span>
        )}
      </Component>
    </>
  );
};

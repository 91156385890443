import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Check if course with course_name exists:
 * @param course_name
 * @returns Boolean | null
 */
export const getCourseUserEnrolled = async (
  course_name: string | undefined
): Promise<boolean | undefined> => {
  if (!course_name) {
    return undefined;
  }

  try {
    const { status } = await axios.get(
      `${apiConstants.base_url}/courses/${course_name}/enrolled`
    );
    if (status === 200) {
      return true;
    } else {
      return undefined;
    }
  } catch (error: any) {
    if (error.response) {
      if (error.response.status === 404) {
        // user not found in course
        return false;
      } else if (error.response.status === 500) {
        throw new Error("Server error occurred");
      }
    }
    return undefined;
  }
};

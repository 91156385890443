import { useQuery } from "@tanstack/react-query";
import { User } from "../../typed";
import { getAuth } from "../../api";

export const useGetAuth = () => {
  return useQuery<User | null, Error>({
    queryKey: ["auth"],
    queryFn: getAuth,
  });
};

import React, { useEffect, useState } from "react";
import classes from "./SignUpLayout.module.scss";
import {
  Button,
  Divider,
  InputField,
  Typography,
  VerticalAccent,
} from "../../components";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh_icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useGetRandomUsername } from "../../hooks";
import { postCourseEnrol, postSignUp } from "../../api";
import { useQueryClient } from "@tanstack/react-query";

export const SignUpLayout = () => {
  const { course_name } = useParams();
  const course_name_stripped = course_name?.replace(/-/g, " ");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [password, setPassword] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: username, refetch: refetchUsername } = useGetRandomUsername();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    setErrorMessage("");
    if (course_name_stripped === undefined) {
      return;
    }

    setIsSubmitting(true);
    if (username && password) {
      try {
        const user = await postSignUp(username, password);
        if (typeof user === "string") {
          setErrorMessage(user);
        } else if (user !== undefined) {
          // Enrol user in the course
          try {
            const enrolResponse = await postCourseEnrol(course_name_stripped);
            console.log("Enrol response", enrolResponse);
            if (enrolResponse !== undefined) {
              queryClient.invalidateQueries({ queryKey: ["auth"] });
              // Store the username in local storage
              localStorage.setItem("username", username);
              navigate(`/course/${enrolResponse}`, { replace: true });
            }
          } catch (error) {}
        }
      } catch (error) {
        console.error("Error signing up:", error);
      }
    }

    setIsSubmitting(false);
  };

  const handleOnClickRefreshUsername = () => {
    refetchUsername();
  };

  useEffect(() => {
    if (username && password) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [username, password]);

  return (
    <div className={classes.signUpContainer}>
      <div className={classes.titleContainer}>
        <VerticalAccent />
        <div>
          <Typography variant="h1">Sign Up</Typography>
          <Typography>
            Create an account to study {course_name_stripped}
          </Typography>
        </div>
      </div>
      <Divider />
      <div className={classes.inputContainer}>
        <Typography
          primary={false}
          tooltip="Randomly generated usernames are used to sign up anonymously. Remember it to log-in again in the future!"
        >
          Username
        </Typography>
        <InputField
          value={username ? username : "Loading..."}
          disabled
          endButtonFunction={handleOnClickRefreshUsername}
          endButtonIcon={<RefreshIcon />}
        />
      </div>
      <div className={classes.inputContainer}>
        <Typography primary={false}>Password</Typography>
        <InputField
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value);
            if (e.target.value.length < 8) {
              setErrorMessage("Password must be at least 8 characters long.");
            } else if (
              errorMessage === "Password must be at least 8 characters long."
            ) {
              setErrorMessage("");
            }
          }}
          type="password"
        />
        {errorMessage !== "" && (
          <Typography variant="error">{errorMessage}</Typography>
        )}
      </div>

      <div>
        <Divider />
        <Typography>
          By signing up, you are agreeing to the collection of your anonymous
          data for research purposes. For details on the data being collected,
          please visit{" "}
          <a
            style={{ display: "inline-block" }}
            href="/research-data-collection"
          >
            research data collection
          </a>
        </Typography>

        <Divider />
      </div>
      <Button
        onClick={handleSubmit}
        icon={<RightArrow />}
        disabled={isSubmitDisabled || isSubmitting || password.length < 8}
      >
        Let’s go!
      </Button>
      <a href="/login">Already have an account? Sign in</a>
    </div>
  );
};

import React, { FC } from "react";
import classes from "./TopicRow.module.scss";
import { Topic } from "../../typed";
import { Chip } from "../Chip/Chip";
import { Typography } from "../Typography/Typography";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";
import { useBreakpoint } from "../../hooks/useBreakpoint";

interface TopicRowProps {
  topic: Topic;
  onclick: (arg0: number) => void;
}

export const TopicRow: FC<TopicRowProps> = ({ topic, onclick }) => {
  const breakpoint = useBreakpoint();

  return (
    <tr
      className={classes.topicRowContainer}
      onClick={() => onclick(topic.topic_id)}
    >
      <td className={classes.titleText}>
        <Typography>{topic.topic_title}</Typography>
      </td>
      <td>
        <Chip variant={topic.status} />
      </td>
      {breakpoint !== "xs" && breakpoint !== "sm" && (
        <td>
          <Typography textAlign="center">
            {topic.attempts ? topic.attempts.toString() : "-"}
          </Typography>
        </td>
      )}
      {breakpoint !== "xs" && (
        <td>
          <Typography textAlign="center">
            {topic.best && topic.best > 0
              ? `${Math.floor(topic.best * 100).toString()}%`
              : "-"}
          </Typography>
        </td>
      )}
      <td className={classes.arrowIcon}>
        <ArrowRightIcon className={classes.arrowIcon} />
      </td>
    </tr>
  );
};

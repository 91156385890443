import React, { FC, useEffect, useRef, useState } from "react";
import classes from "./QuizLayout.module.scss";
import {
  Card,
  Divider,
  IconButton,
  QuizStreak,
  Typography,
} from "../../components";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow_left.svg";
import { ReactComponent as DialogIndentIcon } from "../../assets/icons/dialog_indent.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag_icon.svg";

import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useQuizContext } from "../../contexts";
import { motion } from "framer-motion";
import confettiLottie from "../../assets/lotties/confetti.json";
import Lottie from "lottie-react";
import { postFlagQuestion } from "../../api";

interface QuizLayoutProps {
  dialogBox?: React.ReactNode;
  actionButton?: React.ReactNode;
  children: React.ReactNode;
  onClickBack: () => void;
}

export const QuizLayout: FC<QuizLayoutProps> = ({
  dialogBox,
  actionButton,
  children,
  onClickBack,
}) => {
  const {
    quizQuestions,
    currentQuestionIndex,
    answers,
    setAnswers,
    submitError,
    isShowAnswer,
    isAnswerCorrect,
  } = useQuizContext();
  const breakpoint = useBreakpoint();
  const [characterImage, setCharacterImage] = useState<string>("");
  const isShowAnswerRef = useRef(isShowAnswer);
  const [flaggedIndex, setFlaggedIndex] = useState<number | null>(null);

  // Update ref when isShowAnswer changes
  // To avoid blink animation when answer is shown
  useEffect(() => {
    isShowAnswerRef.current = isShowAnswer;
  }, [isShowAnswer]);

  useEffect(() => {
    setCharacterImage(getCharacterImage());
    const blinkInterval = setInterval(() => {
      if (!isShowAnswerRef.current) {
        setCharacterImage(
          require("../../assets/images/character_dino_blink.png")
        );
        const blinkTimeout = setTimeout(() => {
          if (!isShowAnswerRef.current) {
            setCharacterImage(getCharacterImage());
          }
        }, 200);

        return () => clearTimeout(blinkTimeout);
      }
    }, Math.random() * 5000 + 2000); // Random interval between 2 to 7 seconds

    return () => clearInterval(blinkInterval);
  }, [isShowAnswer, isAnswerCorrect]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFlagQuestion = () => {
    setFlaggedIndex(currentQuestionIndex);

    if (quizQuestions?.[currentQuestionIndex].question_id) {
      postFlagQuestion(quizQuestions[currentQuestionIndex].question_id);
    }

    // mark answer as flagged if unanswered
    if (answers[currentQuestionIndex] === -1) {
      setAnswers((prevAnswers) => {
        const newAnswers = [...prevAnswers];
        newAnswers[currentQuestionIndex] = 2; // flagged
        return newAnswers;
      });
    }
  };

  const getCharacterImage = () => {
    if (isShowAnswer) {
      if (isAnswerCorrect === true) {
        return require("../../assets/images/character_dino_correct.png");
      } else if (isAnswerCorrect === false) {
        return require("../../assets/images/character_dino_incorrect.png");
      } else {
        return require("../../assets/images/character_dino_thinking.png");
      }
    } else {
      return require("../../assets/images/character_dino.png");
    }
  };

  return (
    <div className={classes.quizLayoutContainer}>
      <div className={classes.topBarContainer}>
        {(breakpoint === "xs" ||
          breakpoint === "sm" ||
          breakpoint === "md") && (
          <div className={classes.backButtonContainerMobile}>
            <IconButton icon={<ArrowLeftIcon />} onclick={onClickBack} />
          </div>
        )}
        <QuizStreak
          currentQuestionIndex={currentQuestionIndex}
          answers={answers}
        />
        {(breakpoint === "lg" ||
          breakpoint === "xl" ||
          breakpoint === "xxl" ||
          breakpoint === "xxxl") && (
          <div className={classes.backButtonContainer}>
            <IconButton icon={<ArrowLeftIcon />} onclick={onClickBack} />
          </div>
        )}
      </div>
      <div className={classes.bodyContainer}>
        <motion.div
          className={classes.characterRowContainer}
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div
            layout
            className={[
              classes.character,
              (breakpoint === "xs" || breakpoint === "sm") && classes.sm,
            ].join(" ")}
            initial={{ scaleY: 0, originY: 1 }}
            animate={{ scaleY: 1 }}
            transition={{
              duration: currentQuestionIndex === -1 ? 0.7 : 0.2,
              bounce: currentQuestionIndex === -1 ? 0.55 : 0,
              type: currentQuestionIndex === -1 ? "spring" : "tween",
            }}
          >
            <img
              alt={"happy dinosaur character speaking"}
              src={characterImage}
            ></img>
          </motion.div>
          {dialogBox !== undefined && (
            <motion.div
              layout
              className={[
                classes.dialog,
                (breakpoint === "xs" || breakpoint === "sm") && classes.sm,
              ].join(" ")}
              initial={{ scaleX: 0.5, opacity: 0, originX: 0 }}
              animate={{ scaleX: 1, opacity: 1 }}
              transition={{
                duration: currentQuestionIndex === -1 ? 0.7 : 0.2,
                bounce: currentQuestionIndex === -1 ? 0.55 : 0,
                type: currentQuestionIndex === -1 ? "spring" : "tween",
                delay: currentQuestionIndex === -1 ? 0.25 : 0,
              }}
            >
              <Card shadow>
                {dialogBox}
                <div
                  className={[
                    classes.dialogIndent,
                    (breakpoint === "xs" || breakpoint === "sm") && classes.sm,
                  ].join(" ")}
                >
                  {breakpoint !== "xs" && breakpoint !== "sm" && (
                    <DialogIndentIcon />
                  )}
                </div>
              </Card>
            </motion.div>
          )}
        </motion.div>
        <div className={classes.mainCardContainer}>
          <Card shadow>
            <motion.div
              style={{ width: "100%" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.25 }}
            >
              {children}
            </motion.div>
          </Card>
          {isShowAnswer && isAnswerCorrect && (
            <>
              <div className={classes.confettiLottieContainerLeft}>
                <Lottie loop={false} animationData={confettiLottie} />
              </div>
              <div className={classes.confettiLottieContainerRight}>
                <Lottie loop={false} animationData={confettiLottie} />
              </div>
            </>
          )}
          {currentQuestionIndex >= 10 && (
            <>
              <div className={classes.confettiLottieContainerLeft}>
                <Lottie loop={false} animationData={confettiLottie} />
              </div>
              <div className={classes.confettiLottieContainerRight}>
                <Lottie loop={false} animationData={confettiLottie} />
              </div>
            </>
          )}
        </div>
        {submitError && (
          <div className={classes.errorContainer}>
            <Typography variant="error" fullWidth textAlign="end">
              Unable to submit. Please try again.
            </Typography>
          </div>
        )}
        {actionButton !== undefined && (
          <div className={classes.actionButtonContainer}>
            <div className={classes.flagContainer}>
              {flaggedIndex === currentQuestionIndex ? (
                <Typography
                  primary={false}
                  tooltip="Thank you for flagging the question. You have helped to improve the course"
                >
                  Question marked as poor
                </Typography>
              ) : (
                <>
                  {currentQuestionIndex > -1 && currentQuestionIndex < 10 && (
                    <>
                      <IconButton
                        icon={<FlagIcon />}
                        onclick={handleFlagQuestion}
                      />
                      <Typography primary={false}>
                        Flag poor question
                      </Typography>
                    </>
                  )}
                </>
              )}
            </div>
            {actionButton}
          </div>
        )}
        <div>
          <Divider />
          <Typography primary={false}>
            Please note that AI generates these questions and they may
            occasionally be inaccurate. If you come across any questions that
            seem problematic, we encourage you to flag them.
          </Typography>
        </div>
      </div>
    </div>
  );
};

import React, { FC } from "react";
import classes from "./Chip.module.scss";
import { Typography } from "../Typography/Typography";
import { ReactComponent as StarIcon } from "../../assets/icons/star.svg";
import { ReactComponent as InProgressIcon } from "../../assets/icons/in_progress.svg";
import { ReactComponent as NotStartedIcon } from "../../assets/icons/not_started.svg";
import { useBreakpoint } from "../../hooks/useBreakpoint";

interface ChipProps {
  variant: "Mastered" | "Complete" | "In Progress" | "Not Started";
  icon?: boolean;
  shine?: boolean;
  children?: React.ReactNode;
  fullWidth?: boolean;
  bold?: boolean;
}

export const Chip: FC<ChipProps> = ({
  variant,
  icon = true,
  shine,
  fullWidth,
  bold,
  children,
}) => {
  const breakpoint = useBreakpoint();
  const getIcon = () => {
    // Return the correct icon based on the variant
    switch (variant) {
      case "Mastered":
        return (
          <StarIcon className={[classes.icon, classes.mastered].join(" ")} />
        );
      case "Complete":
        return (
          <StarIcon className={[classes.icon, classes.complete].join(" ")} />
        );
      case "In Progress":
        return (
          <InProgressIcon
            className={[classes.icon, classes.inProgress].join(" ")}
          />
        );
      case "Not Started":
        return (
          <NotStartedIcon
            className={[classes.icon, classes.notStarted].join(" ")}
          />
        );
      default:
        return null;
    }
  };

  const getClass = () => {
    switch (variant) {
      case "Mastered":
        return classes.mastered;
      case "Complete":
        return classes.complete;
      case "In Progress":
        return classes.inProgress;
      case "Not Started":
        return classes.notStarted;
      default:
        return undefined;
    }
  };

  return (
    <div
      className={[
        classes.chip,
        getClass(),
        !icon && classes.autoWidth,
        ((shine === undefined && variant === "Mastered") || shine === true) &&
          classes.shine,
        fullWidth && classes.fullWidth,
      ].join(" ")}
    >
      {icon && breakpoint !== "xs" && getIcon()}
      <Typography
        textAlign={breakpoint === "xs" ? "center" : icon ? "end" : "center"}
        bold={bold}
        fullWidth
      >
        {children ? children : variant}
      </Typography>
    </div>
  );
};

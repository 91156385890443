import React from "react";
import { Typography } from "../../";
import classes from "../QuizDialogueBase.module.scss";
import { useQuizContext } from "../../../contexts/QuizContext";
import { MultipleChoice } from "../../../typed";

export const QuizMCQ = () => {
  const { quizQuestions, currentQuestionIndex } = useQuizContext();
  const currentQuestion = quizQuestions?.[currentQuestionIndex]
    .content as MultipleChoice;

  return (
    <div className={classes.dialogBox}>
      <Typography fullWidth variant="body1">
        {currentQuestion.question}
      </Typography>
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { getTopicName } from "../../api";

export const useGetTopicName = (
  course_id: number | undefined,
  module_id: number | undefined,
  topic_id: number | undefined
) => {
  return useQuery<string | null, Error>({
    queryKey: ["topicName", course_id, module_id, topic_id],
    queryFn: () => getTopicName(course_id, module_id, topic_id),
    enabled:
      course_id !== undefined &&
      module_id !== undefined &&
      topic_id !== undefined,
  });
};

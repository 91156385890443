import React, { FC } from "react";
import {
  QuizActiveMissingWord,
  QuizIntro,
  QuizMCQ,
  QuizShortAnswer,
  QuizCorrectMistake,
  QuizPassiveMissingWord,
  QuizAnswer,
} from "./";
import { useQuizContext } from "../../contexts/QuizContext";

interface QuizDialogueCardProps {
  questionType?: number;
}

export const QuizDialogueCard: FC<QuizDialogueCardProps> = () => {
  const { currentQuestionIndex, quizQuestions, isShowAnswer } =
    useQuizContext();

  if (
    !quizQuestions ||
    currentQuestionIndex === -1 ||
    quizQuestions.length !== 10
  ) {
    return <QuizIntro />;
  }

  const currentQuestion = quizQuestions[currentQuestionIndex];

  if (isShowAnswer) {
    return <QuizAnswer />;
  }

  switch (currentQuestion.type) {
    case "MultipleChoice":
      return <QuizMCQ />;
    case "ShortAnswer":
      return <QuizShortAnswer />;
    case "ActiveMissingWord":
      return <QuizActiveMissingWord />;
    case "CorrectMistake":
      return <QuizCorrectMistake />;
    case "PassiveMissingWord":
      return <QuizPassiveMissingWord />;
    default:
      return <QuizIntro />;
  }
};

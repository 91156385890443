import React, { FC } from "react";
import classes from "./MCQOption.module.scss";
import { Checkbox } from "../Checkbox/Checkbox";
import { Typography } from "../Typography/Typography";
import { motion } from "framer-motion";

interface MCQOptionProps {
  onClick: () => void;
  text: string;
  selected: boolean;
  disabled: boolean;
}

export const MCQOption: FC<MCQOptionProps> = ({
  text,
  selected,
  disabled,
  onClick,
}) => {
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <motion.a
      onClick={handleOnClick}
      className={[
        classes.optionContainer,
        selected && classes.selected,
        disabled && classes.disabled,
      ].join(" ")}
    >
      <Checkbox checked={selected} disabled={disabled} />
      <Typography variant="body1" primary={!disabled}>
        {text}
      </Typography>
    </motion.a>
  );
};

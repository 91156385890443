import React, { useEffect, useState } from "react";
import { Typography } from "../../Typography/Typography";
import { TextArea } from "../../TextArea/TextArea";
import classes from "./QuizShortAnswer.module.scss";
import { useQuizContext } from "../../../contexts";

export const QuizShortAnswer = () => {
  const [answer, setAnswer] = useState<string>("");
  const { isShowAnswer, setCurrentAnswerValue } = useQuizContext();

  useEffect(() => {
    // Update the current answer value in the context
    setCurrentAnswerValue(answer);
  }, [answer, setCurrentAnswerValue]);

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="body1">Can you help out Dino?</Typography>
        <Typography primary={false}>{answer.length} / 360</Typography>
      </div>
      <TextArea
        placeholder="Type your response"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        maxLength={360}
        rows={5}
        isQuiz
        disabled={isShowAnswer}
      ></TextArea>
    </div>
  );
};

import React, { useEffect } from "react";
import { DashboardLayout, RootLayout } from "../../layouts";
import {
  CourseCard,
  DashboardSidebar,
  Skeleton,
  Typography,
} from "../../components";
import { Divider } from "../../components/Divider/Divider";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useGetCourses, useGetStreak } from "../../hooks";
import classes from "./DashboardPage.module.scss";
import { postStreak } from "../../api";
import { useQueryClient } from "@tanstack/react-query";

export const DashboardPage = () => {
  const { data: courses, isLoading } = useGetCourses();
  const {
    data: streak,
    isLoading: isStreakLoading,
    error: isStreakError,
  } = useGetStreak();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const breakpoint = useBreakpoint();
  const handleOnClickCourse = (course_id: number) => {
    navigate(`/course/${course_id}`);
  };

  useEffect(() => {
    const checkStreak = async () => {
      if (streak && streak.length > 0) {
        const localDate = new Date();
        const year = localDate.getFullYear();
        const month = String(localDate.getMonth() + 1).padStart(2, "0");
        const day = String(localDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        if (!streak.includes(formattedDate)) {
          // Add today to streak
          await postStreak();
          queryClient.invalidateQueries({ queryKey: ["streak"] });
        }
      }
    };
    checkStreak();
  }, [streak]);

  return (
    <RootLayout>
      <DashboardLayout
        mobilePadding
        sideBar={
          <DashboardSidebar
            streak={streak}
            isLoading={isStreakLoading}
            error={isStreakError}
          />
        }
        titleBar={
          <>
            <Typography variant="h1">Your Courses</Typography>
            {breakpoint !== "xs" &&
              breakpoint !== "sm" &&
              breakpoint !== "md" && <Divider />}
          </>
        }
      >
        {isLoading ? (
          <div className={classes.loadingSkeletonContainer}>
            <Skeleton maxWidth="220px" dark />
            <Skeleton height="116px" dark />
            <Skeleton height="116px" dark />
            <Skeleton height="116px" dark />
            <Skeleton height="116px" dark />
          </div>
        ) : (
          <>
            {courses !== undefined ? (
              <Typography variant="body1" fullWidth>
                2024 - Semester 2
              </Typography>
            ) : (
              <Typography variant="body1" fullWidth primary={false}>
                Unable to load your courses. Please try again later.
              </Typography>
            )}
            {courses?.map((course, index) => (
              <CourseCard
                key={index}
                onClick={() => handleOnClickCourse(course.course_id)}
                title={course.course_name}
                lastStudied={course.last_studied}
                progress={course.progress}
                numberOfModules={course.number_of_modules}
                index={index}
              />
            ))}
          </>
        )}
      </DashboardLayout>
    </RootLayout>
  );
};

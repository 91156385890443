import React from "react";
import { Typography } from "../../Typography/Typography";
import classes from "./QuizShortAnswer.module.scss";
import { useQuizContext } from "../../../contexts/QuizContext";
import { ShortAnswer } from "../../../typed";

export const QuizShortAnswer = () => {
  const { quizQuestions, currentQuestionIndex } = useQuizContext();
  const currentQuestion = quizQuestions?.[currentQuestionIndex]
    .content as ShortAnswer;

  return (
    <div className={classes.dialogBox}>
      <Typography fullWidth variant="body1">
        {currentQuestion.question}
      </Typography>
    </div>
  );
};

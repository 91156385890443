import React, { FC } from "react";
import classes from "./IconButton.module.scss";
import { motion } from "framer-motion";

interface IconButtonProps {
  icon: React.ReactNode;
  onclick?: () => void;
}

export const IconButton: FC<IconButtonProps> = ({ icon, onclick }) => {
  return (
    <motion.a className={classes.iconContainer} onClick={onclick}>
      {icon}
    </motion.a>
  );
};

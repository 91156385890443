import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Check Auth Token:
 * @returns User { username: string, role: string, user_id: number } | null
 */
export const getFeedbackGiven = async (): Promise<boolean | null> => {
  try {
    // Fetch auth token status
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/feedback`
    );
    if (status === 200) {
      return data.feedbackGiven;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

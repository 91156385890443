import React, { FC } from "react";
import classes from "./DashboardSidebar.module.scss";
import { Typography } from "../Typography/Typography";
import { Divider } from "../Divider/Divider";
import Calendar from "react-calendar";
import "./Calendar.scss";
import { Chip } from "../Chip/Chip";
import { useGetAuth, useGetStreak } from "../../hooks";
import { Streak } from "../../typed";
import { Button } from "../Button/Button";
import { feedbackConstants } from "../../constants/feedbackConstants";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";

interface DashboardSidebarProps {
  streak: Streak | undefined;
  isLoading: boolean;
  error: Error | null;
}

export const DashboardSidebar: FC<DashboardSidebarProps> = ({
  streak,
  isLoading,
  error,
}) => {
  const { data: user } = useGetAuth();

  if (isLoading || error) {
    return <></>;
  }

  const handleOnClickShareFeedback = () => {
    if (feedbackConstants.surveyURL) {
      window.open(feedbackConstants.surveyURL, "_blank")?.focus();
    }
  };

  // Function to convert a date to the local timezone and format it as 'YYYY-MM-DD'
  const toLocalISOString = (date: Date) => {
    const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
    return new Date(date.getTime() - tzOffset).toISOString().split("T")[0];
  };

  // Function to determine if a date should be highlighted
  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month" && streak) {
      const dateString = toLocalISOString(date);
      if (streak.includes(dateString)) {
        return "highlight"; // Add your custom class for highlighting
      }
      if (date > new Date()) {
        return "future-day"; // Add your custom class for future dates
      }
    }
    return null;
  };

  // Calculate the streak length
  const getStreakLength = () => {
    const today = new Date();
    let streakLength = 0;

    if (streak && streak.includes(today.toISOString().split("T")[0])) {
      streakLength = 1;
      for (let i = 1; i < streak.length; i++) {
        const currentDate = new Date(today);
        currentDate.setDate(currentDate.getDate() - i);
        const dateString = currentDate.toISOString().split("T")[0];
        if (streak.includes(dateString)) {
          streakLength++;
        } else {
          break;
        }
      }
    }
    return streakLength;
  };

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.titleContainer}>
        <Typography variant="body1">
          Welcome back, <strong>{user?.username}</strong>
        </Typography>
        <Divider />
      </div>
      <div className={classes.calendarContainer}>
        <Typography variant="body1">
          {new Date().toLocaleString("en-US", {
            month: "long",
            year: "numeric",
          })}
        </Typography>
        <div className={classes.gap} />
        <Calendar showNavigation={false} tileClassName={tileClassName} />
        <div className={classes.gap} />
        {streak?.length === 0 ||
        streak?.length === 1 ||
        streak === undefined ? (
          <Chip bold variant="Not Started" icon={false} fullWidth>
            Welcome! Try build a streak
          </Chip>
        ) : getStreakLength() > 4 ? (
          <Chip bold variant="Mastered" icon={false} fullWidth>
            {getStreakLength()} Day Learning Streak
          </Chip>
        ) : getStreakLength() > 1 ? (
          <Chip bold variant="Complete" shine icon={false} fullWidth>
            {getStreakLength()} Day Learning Streak
          </Chip>
        ) : (
          <Chip bold variant="Not Started" icon={false} fullWidth>
            Welcome Back! Try keep your streak
          </Chip>
        )}
        <div className={classes.gap} />
        <Divider />
      </div>
      <div className={classes.feedbackContainer}>
        <Typography>Help us improve your experience (&lt;2 minutes)</Typography>
        <Button
          fullWidth
          icon={<ArrowRightIcon />}
          onClick={handleOnClickShareFeedback}
        >
          Share Your Feedback
        </Button>
      </div>
    </div>
  );
};

import React from "react";
import classes from "./TopicRowSkeleton.module.scss";
import { Skeleton } from "../";

export const TopicRowSkeleton = () => {
  return (
    <tr className={classes.topicRowContainer}>
      <td className={classes.startSkeleton}>
        <Skeleton />
      </td>
      <td>
        <Skeleton />
      </td>
      <td colSpan={3} className={classes.endSkeleton}>
        <Skeleton />
      </td>
    </tr>
  );
};

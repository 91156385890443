import React, {
  createContext,
  useContext,
  useState,
  useRef,
  ReactNode,
} from "react";
import { useGetQuizQuestions } from "../hooks";
import { useParams } from "react-router-dom";
import { QuizQuestion } from "../typed";

interface QuizContextType {
  isAnswerCorrect: boolean | undefined;
  setIsAnswerCorrect: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isShowAnswer: boolean;
  setIsShowAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  submitError: boolean;
  setSubmitError: React.Dispatch<React.SetStateAction<boolean>>;
  isActionDisabled: boolean;
  setIsActionDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  quizQuestions: QuizQuestion[] | null | undefined;
  isLoading: boolean;
  isError: boolean;
  answers: number[];
  setAnswers: React.Dispatch<React.SetStateAction<number[]>>;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  xpEarned: number;
  setXpEarned: React.Dispatch<React.SetStateAction<number>>;
  timeTaken: number;
  startTimer: () => void;
  stopTimer: () => void;
  currentAnswerValue: number | string | undefined;
  setCurrentAnswerValue: React.Dispatch<
    React.SetStateAction<number | string | undefined>
  >;
  answerExplanation: string | undefined;
  setAnswerExplanation: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const QuizContext = createContext<QuizContextType | undefined>(undefined);

export const QuizProvider = ({ children }: { children: ReactNode }) => {
  const { course_id, module_id, topic_id } = useParams();
  const course_id_number = course_id ? parseInt(course_id) : undefined;
  const module_id_number = module_id ? parseInt(module_id) : undefined;
  const topic_id_number = topic_id ? parseInt(topic_id) : undefined;
  const {
    data: quizQuestions,
    isLoading,
    isError,
  } = useGetQuizQuestions(course_id_number, module_id_number, topic_id_number);

  const [isAnswerCorrect, setIsAnswerCorrect] = useState<boolean | undefined>(
    undefined
  );
  const [isShowAnswer, setIsShowAnswer] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [isActionDisabled, setIsActionDisabled] = useState<boolean>(false);
  const [answers, setAnswers] = useState<number[]>(new Array(10).fill(-1));
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
  const [xpEarned, setXpEarned] = useState<number>(0);
  const [timeTaken, setTimeTaken] = useState<number>(0);
  const [currentAnswerValue, setCurrentAnswerValue] = useState<
    number | string | undefined
  >(undefined);
  const [answerExplanation, setAnswerExplanation] = useState<
    string | undefined
  >(undefined);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      setTimeTaken((prevTime) => prevTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  return (
    <QuizContext.Provider
      value={{
        isAnswerCorrect,
        setIsAnswerCorrect,
        isShowAnswer,
        setIsShowAnswer,
        submitError,
        setSubmitError,
        isActionDisabled,
        setIsActionDisabled,
        quizQuestions,
        isLoading,
        isError,
        answers,
        setAnswers,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        xpEarned,
        setXpEarned,
        timeTaken,
        startTimer,
        stopTimer,
        currentAnswerValue,
        setCurrentAnswerValue,
        answerExplanation,
        setAnswerExplanation,
      }}
    >
      {children}
    </QuizContext.Provider>
  );
};

export const useQuizContext = () => {
  const context = useContext(QuizContext);
  if (context === undefined) {
    throw new Error("useQuizContext must be used within a QuizProvider");
  }
  return context;
};

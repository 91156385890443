import React, { FC, SetStateAction } from "react";
import classes from "./Modal.module.scss";
import { Card, Divider, Button, Typography } from "../";

interface ModalProps {
  title?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  closeOnBackdropClick?: boolean;
  cancellable?: boolean;
  confirmDisabled?: boolean;
  children?: React.ReactNode;
}

export const Modal: FC<ModalProps> = ({
  title,
  confirmText = "Confirm",
  confirmDisabled = false,
  cancelText = "Cancel",
  onConfirm,
  isOpen,
  setIsOpen,
  closeOnBackdropClick = true,
  cancellable = true,
  children,
}) => {
  if (!isOpen) return <></>;

  return (
    <div
      className={classes.modalBackground}
      onClick={(e) => {
        e.stopPropagation();
        if (closeOnBackdropClick) {
          setIsOpen(false);
        }
      }}
    >
      <div
        className={classes.modalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <Card>
          {title !== undefined && (
            <>
              <div className={classes.modalTitle}>
                <Typography variant="h2">{title}</Typography>
              </div>
              <Divider onCard />
            </>
          )}
          <div className={classes.modalBody}>{children}</div>
          <Divider onCard />
          <div className={classes.modalFooter}>
            {cancellable && (
              <Button
                secondary
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              >
                {cancelText}
              </Button>
            )}
            <Button onClick={onConfirm} disabled={confirmDisabled}>
              {confirmText}
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

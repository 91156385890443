import React, { FC } from "react";
import { RootLayout } from "../../layouts";
import { Button, Card, Typography } from "../../components";
import classes from "./NotFoundPage.module.scss";
import { useNavigate } from "react-router-dom";

interface NotFoundPageProps {
  message?: string;
}

export const NotFoundPage: FC<NotFoundPageProps> = ({
  message = undefined,
}) => {
  const navigate = useNavigate();

  const handleOnClickHome = () => {
    // Navigate to home page
    navigate("/");
  };

  return (
    <RootLayout>
      <div className={classes.pageContainer}>
        <div className={classes.cardContainer}>
          <img
            alt=""
            src={require("../../assets/images/character_dino_thinking.png")}
            className={classes.character}
          />
          <Card>
            <div className={classes.cardInner}>
              <div className={classes.cardTextContainer}>
                <Typography variant="h2" textAlign="center">
                  Whoops! Page not found
                </Typography>
                {message && (
                  <Typography variant="body1" textAlign="center">
                    {message}
                  </Typography>
                )}
              </div>
              <Button onClick={handleOnClickHome}>Home</Button>
            </div>
          </Card>
        </div>
      </div>
    </RootLayout>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { Streak } from "../../typed";

/**
 * Get User Streak:
 * @returns Streak | null
 */
export const getStreak = async (): Promise<Streak | undefined> => {
  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/user/streak`
    );
    if (status === 200) {
      return data.streak;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

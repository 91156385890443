import { useQuery } from "@tanstack/react-query";
import { Module } from "../../typed";
import { getCourse } from "../../api";

export const useGetCourse = (course_id: number | undefined) => {
  return useQuery<Module[] | undefined, Error>({
    queryKey: ["course", course_id],
    queryFn: () => getCourse(course_id),
    enabled: course_id !== undefined,
  });
};

import React from "react";
import { RootLayout } from "../RootLayout/RootLayout";
import classes from "./LoadingLayout.module.scss";
import loadingLottie from "../../assets/lotties/loading-green.json";
import Lottie from "lottie-react";

export const LoadingLayout = () => {
  return (
    <RootLayout>
      <div className={classes.loadingContainer}>
        <Lottie
          animationData={loadingLottie}
          style={{
            maxHeight: "80px",
            maxWidth: "300px",
            objectFit: "cover",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </div>
    </RootLayout>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Generate 5 MCQ questions
 * @param question_id
 */
export const postFlagQuestion = async (
  question_id: number
): Promise<boolean | undefined> => {
  try {
    const { status } = await axios.post(
      `${apiConstants.base_url}/quiz/flag-question`,
      {
        question_id: question_id,
      }
    );
    if (status === 200) {
      return true;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

import React, { FC, useState } from "react";
import classes from "./Button.module.scss";
import { Typography } from "../";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";

interface ButtonProps {
  onClick: (e: React.SyntheticEvent) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  narrow?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
}
export const Button: FC<ButtonProps> = ({
  onClick,
  icon,
  disabled,
  narrow,
  fullWidth = false,
  secondary = false,
  children,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <motion.button
      whileTap={{ scale: 1 }}
      className={[
        classes.button,
        narrow === true && classes.narrow,
        secondary && classes.secondary,
        fullWidth && classes.fullWidth,
      ].join(" ")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
      disabled={disabled}
      transition={{ duration: 0.2 }}
    >
      <LayoutGroup>
        <motion.div
          layout
          className={classes.textContainer}
          animate={{ flexGrow: icon ? (hover ? 1 : 0) : 1 }}
          transition={{ duration: 0.2 }}
        >
          <Typography variant="button" textAlign="center">
            {children}
          </Typography>
        </motion.div>
        <AnimatePresence>
          {!hover && icon && (
            <motion.div
              layout
              className={classes.icon}
              initial={{ width: "16px", opacity: 0 }}
              animate={{ width: hover ? 0 : "16px", opacity: hover ? 0 : 1 }}
              exit={{ width: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {icon}
            </motion.div>
          )}
        </AnimatePresence>
      </LayoutGroup>
    </motion.button>
  );
};

import { useQuery } from "@tanstack/react-query";
import { Module } from "../../typed";
import { getCourseExists } from "../../api";

export const useGetCourseExists = (course_name: string | undefined) => {
  return useQuery<Number | undefined, Error>({
    queryKey: ["course_exists", course_name],
    queryFn: () => getCourseExists(course_name),
    enabled: course_name !== undefined,
  });
};

import React, { FC } from "react";
import classes from "./Card.module.scss";

interface CardProps {
  shadow?: boolean;
  disablePadding?: boolean;
  children: React.ReactNode;
}

export const Card: FC<CardProps> = ({
  shadow = false,
  disablePadding = false,
  children,
}) => {
  return (
    <div
      className={[
        classes.card,
        shadow && classes.shadow,
        disablePadding && classes.noPadding,
      ].join(" ")}
    >
      {children}
    </div>
  );
};

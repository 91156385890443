import { IconButton, Typography } from "../";
import classes from "./Header.module.scss";
import { ReactComponent as SignOutIcon } from "../../assets/icons/sign-out.svg";
import { postSignOut } from "../../api";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetAuth } from "../../hooks";

export const Header = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: user } = useGetAuth();

  const handleOnClickHome = () => {
    navigate("/");
  };

  const handleOnClickSignOut = async () => {
    try {
      await postSignOut();
      queryClient.invalidateQueries({ queryKey: ["auth"] });
      queryClient.clear();
      navigate("/login");
    } catch (error) {}
  };

  return (
    <div className={classes.header}>
      <button className={classes.headerTitle} onClick={handleOnClickHome}>
        <Typography variant="h1">
          Study<span style={{ opacity: "40%" }}>Ascent</span>
        </Typography>
      </button>
      <div className={classes.endGroup}>
        <Typography primary={false}>Created by Tom Leigh</Typography>
        {user && (
          <IconButton icon={<SignOutIcon />} onclick={handleOnClickSignOut} />
        )}
      </div>
    </div>
  );
};

import React, { FC, useState } from "react";
import classes from "./CourseRow.module.scss";
import { LayoutGroup, motion } from "framer-motion";
import { ReactComponent as ExpandIcon } from "../../assets/icons/chevron_right.svg";
import { Typography } from "../Typography/Typography";
import CircularProgress from "../CircularProgress/CircularProgress";
import { TopicsTable } from "../TopicsTable/TopicsTable";
import { useBreakpoint } from "../../hooks/useBreakpoint";

interface CourseRowProps {
  course_id: number | undefined;
  module_id: number | undefined;
  title: string;
  progress: number;
  numberOfTopics: number;
  initialOpenState?: boolean;
}

export const CourseRow: FC<CourseRowProps> = ({
  course_id,
  module_id,
  title,
  progress,
  numberOfTopics,
  initialOpenState = false,
}) => {
  const [expanded, setExpanded] = useState(initialOpenState);
  const breakpoint = useBreakpoint();
  const handleOnClickExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className={classes.mainContainer}>
      <LayoutGroup>
        <motion.a
          className={[classes.rowContainer, expanded && classes.expanded].join(
            " "
          )}
          onClick={handleOnClickExpand}
        >
          <div className={classes.rowHeader}>
            <div className={classes.titleContainer}>
              <ExpandIcon
                className={[
                  classes.expandIcon,
                  expanded && classes.expanded,
                ].join(" ")}
              />
              <Typography bold={expanded}>{title}</Typography>
            </div>
            <div className={classes.progressContainer}>
              {breakpoint !== "xs" && (
                <Typography primary={false}>
                  {progress.toString()}/{numberOfTopics.toString()}
                </Typography>
              )}
              <CircularProgress
                progress={progress / numberOfTopics}
                size={28}
                stroke={3}
              />
            </div>
          </div>
        </motion.a>
        <motion.div
          layout
          className={classes.rowContent}
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: expanded ? "auto" : 0,
            opacity: expanded ? 1 : 0,
          }}
          transition={{ duration: 0.2 }}
          style={{ overflow: "hidden" }}
        >
          {expanded && (
            <TopicsTable
              expanded={expanded}
              numberOfTopics={numberOfTopics}
              course_id={course_id}
              module_id={module_id}
            />
          )}
        </motion.div>
      </LayoutGroup>
    </div>
  );
};

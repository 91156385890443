import React, { FC, useState } from "react";
import classes from "./InputField.module.scss";
import { ReactComponent as PasswordHiddenIcon } from "../../assets/icons/password_hidden_icon.svg";
import { ReactComponent as PasswordVisibleIcon } from "../../assets/icons/password_visible_icon.svg";

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  endButtonFunction?: () => void;
  endButtonIcon?: React.ReactNode;
}

export const InputField: FC<InputFieldProps> = ({
  type = "text",
  endButtonFunction,
  endButtonIcon,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleOnClickShowPassword = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleOnClickEndButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (endButtonFunction) {
      endButtonFunction();
    }
  };

  return (
    <div className={classes.inputFieldContainer}>
      <input
        className={classes.inputField}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        {...rest}
      />
      {endButtonIcon && (
        <button
          onClick={(e) => {
            handleOnClickEndButton(e);
          }}
        >
          {endButtonIcon}
        </button>
      )}

      {type === "password" && (
        <button
          onClick={(e) => {
            handleOnClickShowPassword(e);
          }}
        >
          {showPassword ? <PasswordVisibleIcon /> : <PasswordHiddenIcon />}
        </button>
      )}
    </div>
  );
};

import React from "react";
import { DashboardLayout, RootLayout, SignInLayout } from "../../layouts";

export const LogInPage = () => {
  return (
    <RootLayout>
      <DashboardLayout isAuthPage titleBar={<></>} sideBar={<SignInLayout />}>
        <></>
      </DashboardLayout>
    </RootLayout>
  );
};

import { Header } from "../../components";
import classes from "./RootLayout.module.scss";
import { FC, forwardRef } from "react";

interface RootLayoutProps {
  children: React.ReactNode;
  mainContainerRef?: React.RefObject<HTMLDivElement>;
}

export const RootLayout: FC<RootLayoutProps> = (
  { children, mainContainerRef },
  ref
) => {
  return (
    <div className={classes.rootContainer}>
      <Header />
      <div className={classes.mainContainer} ref={mainContainerRef}>
        {children}
      </div>
    </div>
  );
};

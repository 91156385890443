import React from "react";
import classes from "./CourseSidebar.module.scss";
import { Leaderboard } from "../Leaderboard/Leaderboard";
import { Typography } from "../";
import { Divider } from "../Divider/Divider";

export const CourseSidebar = () => {
  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.titleContainer}>
        <Typography variant="h2">Leaderboard</Typography>
        <Divider />
      </div>
      <Leaderboard />
    </div>
  );
};

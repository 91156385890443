import React, { useEffect, useState } from "react";
import { Typography, Divider, ChipSelectable } from "../../";
import { useQuizContext } from "../../../contexts";
import { PassiveMissingWord } from "../../../typed";
import classes from "./QuizPassiveMissingWord.module.scss";

export const QuizPassiveMissingWord = () => {
  const [displayedValue, setDisplayedValue] = useState("_____");
  const [options, setOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const {
    quizQuestions,
    currentQuestionIndex,
    isShowAnswer,
    setCurrentAnswerValue,
  } = useQuizContext();
  const currentQuestion = quizQuestions?.[currentQuestionIndex]
    .content as PassiveMissingWord;

  useEffect(() => {
    // Combine false_values and missing_value
    const combinedOptions = [
      ...currentQuestion.false_values,
      currentQuestion.missing_value,
    ];

    // Shuffle the options
    for (let i = combinedOptions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [combinedOptions[i], combinedOptions[j]] = [
        combinedOptions[j],
        combinedOptions[i],
      ];
    }

    setOptions(combinedOptions);
  }, [currentQuestion]);

  const handleChipClick = (value: string) => {
    if (value === selectedOption) {
      // handle deselect
      setDisplayedValue("_____");
      setSelectedOption(null);
      setCurrentAnswerValue(undefined);
    } else {
      // select new value
      setDisplayedValue(value);
      setSelectedOption(value);
      setCurrentAnswerValue(value);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.optionsContainer}>
        <Typography variant="body1">
          {currentQuestion.sentence_parts[0]}
          <strong>{displayedValue}</strong>
          {currentQuestion.sentence_parts[1]}
        </Typography>
      </div>
      <Divider />
      <div className={classes.justificationContainer}>
        <Typography
          variant="body1"
          tooltip="The length of the space does not suggest the correct option"
        >
          What do you think is missing?
        </Typography>
      </div>
      <div className={classes.chipsContainer}>
        {options.map((option, index) => (
          <ChipSelectable
            key={index}
            selected={selectedOption === option}
            onClick={() => handleChipClick(option)}
            disabled={isShowAnswer}
          >
            {option}
          </ChipSelectable>
        ))}
      </div>
    </div>
  );
};

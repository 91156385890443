import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Get Quiz Questions:
 * @param module_id
 * @param topic_id
 * @returns topic_id (number) | null
 */
export const getNextTopicID = async (
  module_id: number | undefined,
  topic_id: number | undefined
): Promise<number | null> => {
  if (!module_id || !topic_id) {
    return null;
  }
  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/quiz/${module_id}/${topic_id}/next-topic-id`
    );
    console.log(data);
    if (status === 200) {
      return data.next_topic_id;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

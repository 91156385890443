import { useQuery } from "@tanstack/react-query";
import { getNextTopicID } from "../../api";

export const useGetNextTopicID = (
  module_id: number | undefined,
  topic_id: number | undefined
) => {
  return useQuery<number | null, Error>({
    queryKey: ["quizQuestions", module_id, topic_id],
    queryFn: () => getNextTopicID(module_id, topic_id),
    enabled: module_id !== undefined && topic_id !== undefined,
  });
};

import React, { FC } from "react";
import classes from "./Skeleton.module.scss";
import { join } from "path";

interface SkeletonProps {
  maxWidth?: string;
  height?: string;
  dark?: boolean;
}

export const Skeleton: FC<SkeletonProps> = ({
  maxWidth = undefined,
  height = undefined,
  dark = false,
}) => {
  return (
    <div
      className={[classes.skeleton, dark && classes.dark].join(" ")}
      style={{
        maxWidth: maxWidth ? maxWidth : "auto",
        height: height ? height : undefined,
      }}
    />
  );
};

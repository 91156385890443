import { useQuery } from "@tanstack/react-query";
import { Streak } from "../../typed";
import { getStreak } from "../../api";

export const useGetStreak = () => {
  return useQuery<Streak | undefined, Error>({
    queryKey: ["streak"],
    queryFn: () => getStreak(),
  });
};

import React from "react";
import classes from "./QuizComplete.module.scss";
import { Button, Chip, Divider, RampNumberText, Typography } from "../../";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { useNavigate, useParams } from "react-router-dom";
import { useQuizContext } from "../../../contexts";
import { feedbackConstants } from "../../../constants/feedbackConstants";
import { useGetNextTopicID } from "../../../hooks";

export const QuizComplete = () => {
  const { course_id, module_id, topic_id } = useParams();
  const { answers, timeTaken } = useQuizContext();
  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  const module_id_number = module_id ? parseInt(module_id) : undefined;
  const topic_id_number = topic_id ? parseInt(topic_id) : undefined;
  const { data: nextTopicID } = useGetNextTopicID(
    module_id_number,
    topic_id_number
  );

  const handleOnClickShareFeedback = () => {
    if (feedbackConstants.surveyURL) {
      window.open(feedbackConstants.surveyURL, "_blank")?.focus();
    }
  };

  const handleOnClickRetry = () => {
    window.location.reload();
  };

  const handleOnClickDashboard = () => {
    navigate(`/course/${course_id}`);
  };

  const handleOnClickNextTopic = () => {
    navigate(`/course/${course_id}/${module_id}/${nextTopicID}/quiz`, {
      replace: true,
    });
    window.location.reload();
  };

  const calculateBestStreak = (answers: number[]): number => {
    /**
     * Returns the longest streak of correct answers (consecutive 1s) in the answers array
     * @param answers - Array of 0s and 1s representing incorrect and correct answers respectively
     * @returns number - The longest streak of correct answers
     */

    let bestStreak = 0;
    let currentStreak = 0;
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === 1) {
        currentStreak++;
        bestStreak = Math.max(bestStreak, currentStreak);
      } else {
        currentStreak = 0;
      }
    }
    return bestStreak;
  };

  const calculateTotalCorrect = (answers: number[]): number => {
    let correct = 0;
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === 1) {
        correct++;
      }
    }
    return correct;
  };

  const calculatePercentageCorrect = (answers: number[]): number => {
    const total = answers.length;
    const skipped = answers.filter((answer) => answer === 2).length;
    const correct = calculateTotalCorrect(answers);
    if (total === skipped) {
      return 0;
    }
    return Math.floor((correct / (total - skipped)) * 100);
  };

  const getTitleText = () => {
    const score = calculatePercentageCorrect(answers);
    if (score >= 90) {
      return "Excellent work! You've mastered this topic";
    } else if (score >= 70) {
      return "Congratulations! You've completed this topic";
    } else if (score >= 50) {
      return "Good effort! Continue practicing and score 70% to complete this topic";
    } else if (score >= 30) {
      return "You're getting there! Keep practicing to complete this topic";
    } else {
      return "That was a tough one! Keep practicing to complete this topic";
    }
  };

  const getStatusChip = () => {
    const score = calculatePercentageCorrect(answers);
    if (score >= 90) {
      return (
        <Chip variant="Mastered" icon={false}>
          Topic Mastered
        </Chip>
      );
    } else if (score >= 70) {
      return (
        <Chip variant="Complete" shine icon={false}>
          Topic Complete
        </Chip>
      );
    } else {
      return (
        <Chip variant="In Progress" icon={false}>
          Topic Incomplete
        </Chip>
      );
    }
  };

  const formatTimerText = (time: number) => {
    /**
     * Convert time in seconds to Xh Ym Zs format
     * With number ramping
     */
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return (
      <>
        {hours > 0 && (
          <>
            <RampNumberText targetNumber={hours} />
            {"h "}
          </>
        )}
        {minutes > 0 && (
          <>
            <RampNumberText targetNumber={minutes} />
            {"m "}
          </>
        )}
        <RampNumberText targetNumber={seconds} />
        {"s"}
      </>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="body1" fullWidth textAlign="center">
          <strong>{getTitleText()}</strong>
        </Typography>
      </div>
      <Divider onCard />
      {getStatusChip()}
      <div className={classes.statsRow}>
        <div className={classes.statCard}>
          <Typography variant="h1" component="p">
            <RampNumberText
              targetNumber={calculatePercentageCorrect(answers)}
            />
            %
          </Typography>
          <Typography primary={false}>Quiz Score</Typography>
        </div>

        <div className={classes.statCard}>
          <Typography variant="h1" component="p">
            <RampNumberText targetNumber={calculateBestStreak(answers)} />
          </Typography>
          <Typography primary={false}>Best Streak</Typography>
        </div>

        <div className={classes.statCard}>
          <Typography variant="h1" component="p">
            <RampNumberText
              targetNumber={calculateTotalCorrect(answers) * 10}
            />
          </Typography>
          <Typography primary={false}>XP Earned</Typography>
        </div>

        <div className={classes.statCard}>
          <Typography variant="h1" component="p">
            {formatTimerText(timeTaken)}
          </Typography>
          <Typography primary={false}>Time Taken</Typography>
        </div>
      </div>
      <Divider onCard={true} />
      <div className={classes.buttonRow}>
        <Button
          narrow={
            breakpoint === "xs" ||
            breakpoint === "sm" ||
            breakpoint === "md" ||
            breakpoint === "lg"
          }
          onClick={handleOnClickRetry}
        >
          Retry
        </Button>
        <Button
          narrow={
            breakpoint === "xs" ||
            breakpoint === "sm" ||
            breakpoint === "md" ||
            breakpoint === "lg"
          }
          onClick={handleOnClickDashboard}
        >
          Dashboard
        </Button>
        <Button
          narrow={
            breakpoint === "xs" ||
            breakpoint === "sm" ||
            breakpoint === "md" ||
            breakpoint === "lg"
          }
          disabled={!nextTopicID}
          onClick={handleOnClickNextTopic}
        >
          Next Topic
        </Button>
        <Button
          narrow={
            breakpoint === "xs" ||
            breakpoint === "sm" ||
            breakpoint === "md" ||
            breakpoint === "lg"
          }
          secondary
          onClick={handleOnClickShareFeedback}
        >
          Share Feedback
        </Button>
      </div>
    </div>
  );
};

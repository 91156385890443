import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import {
  CoursePage,
  DashboardPage,
  QuizPage,
  LogInPage,
  SignUpPage,
  NotFoundPage,
  AdminPage,
  DataCollectionPage,
} from "./pages";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AnonymousGuard, AuthGuard, SurveyGuard } from "./components";
import axios from "axios";
import { AdminCreateQuizPage } from "./pages/AdminPage/AdminCreateQuizPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <SurveyGuard>
          <DashboardPage />
        </SurveyGuard>
      </AuthGuard>
    ),
  },
  {
    path: "/login",
    element: (
      <AnonymousGuard>
        <LogInPage />
      </AnonymousGuard>
    ),
  },
  {
    path: "/signup/:course_name",
    element: <SignUpPage />,
  },
  {
    path: "/course/:course_id",
    element: (
      <AuthGuard>
        <SurveyGuard>
          <CoursePage />
        </SurveyGuard>
      </AuthGuard>
    ),
  },
  {
    path: "/course/:course_id/:module_id/:topic_id/quiz",
    element: (
      <AuthGuard>
        <SurveyGuard>
          <QuizPage />
        </SurveyGuard>
      </AuthGuard>
    ),
  },
  /*{
    path: "/admin",
    element: (
      <AuthGuard>
        <AdminPage />
      </AuthGuard>
    ),
  },
  {
    path: "/admin/create-quiz",
    element: (
      <AuthGuard>
        <AdminCreateQuizPage />
      </AuthGuard>
    ),
  },*/
  {
    path: "/research-data-collection",
    element: <DataCollectionPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);

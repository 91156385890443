import React, { FC } from "react";
import classes from "./VerticalAccent.module.scss";

interface VerticalAccentProps {
  variant?: number;
}

export const VerticalAccent: FC<VerticalAccentProps> = ({ variant = 1 }) => {
  return (
    <div
      className={[
        classes.accent,
        classes[["accent", variant.toString()].join("")],
      ].join(" ")}
    />
  );
};

import { useEffect, useRef } from "react";
import classes from "./Leaderboard.module.scss";
import { Typography } from "../Typography/Typography";
import { useGetAuth, useGetCourseLeaderboard } from "../../hooks";
import { useParams } from "react-router-dom";

export const Leaderboard = () => {
  const { course_id } = useParams();
  const { data: authData } = useGetAuth();
  const course_id_number = course_id ? parseInt(course_id) : undefined;
  const {
    data: leaderboard,
    isLoading,
    error,
  } = useGetCourseLeaderboard(course_id_number);

  const containerRef = useRef<HTMLDivElement>(null);
  const currentUserRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentUserRowRef.current && containerRef.current) {
      const container = containerRef.current;
      const currentUserRow = currentUserRowRef.current;
      const offsetTop = currentUserRow.offsetTop;
      const containerHeight = container.clientHeight;
      const scrollTop = offsetTop - containerHeight / 2;
      container.scrollTo({ top: scrollTop, behavior: "smooth" });
    }
  }, [leaderboard]);

  return (
    <div className={classes.leaderboardContainer} ref={containerRef}>
      {error ? (
        <Typography>Unable to load leaderboard</Typography>
      ) : isLoading ? (
        Array.from({ length: 20 }).map((_, index) => {
          return (
            <div
              key={index}
              className={[
                classes.leaderboardRow,
                index % 2 === 0 && classes.even,
              ].join(" ")}
            ></div>
          );
        })
      ) : (
        <>
          {leaderboard?.map((data, index) => {
            const isCurrentUser = authData && authData.user_id === data.user_id;
            return (
              <div
                key={index}
                className={[
                  classes.leaderboardRow,
                  index % 2 === 0 && classes.even,
                ].join(" ")}
                ref={isCurrentUser ? currentUserRowRef : null}
              >
                <div className={classes.rank}>
                  <Typography>{index + 1}</Typography>
                </div>
                <div className={classes.name}>
                  <Typography textAlign="start">
                    {data.username}
                    {isCurrentUser && " (You)"}
                  </Typography>
                </div>
                <div
                  className={[
                    classes.score,
                    isCurrentUser && classes.currentUserScore,
                  ].join(" ")}
                >
                  <Typography textAlign="end">{data.score} XP</Typography>
                </div>
              </div>
            );
          })}
          {leaderboard &&
            leaderboard.length < 20 &&
            Array.from({ length: 20 - leaderboard.length }).map((_, index) => {
              return (
                <div
                  key={index}
                  className={[
                    classes.leaderboardRow,
                    leaderboard.length % 2 === 0
                      ? index % 2 === 0 && classes.even
                      : index % 2 !== 0 && classes.even,
                  ].join(" ")}
                ></div>
              );
            })}
        </>
      )}
    </div>
  );
};

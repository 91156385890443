import React from "react";
import {
  DashboardLayout,
  EnrolLayout,
  LoadingLayout,
  RootLayout,
  SignUpLayout,
} from "../../layouts";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetAuth,
  useGetCourseExists,
  useGetCourseUserEnrolled,
} from "../../hooks";
import { NotFoundPage } from "../";
import classes from "./SignUpPage.module.scss";
import { Typography } from "../../components";

export const SignUpPage = () => {
  const navigate = useNavigate();
  const { course_name } = useParams();
  const course_name_stripped = course_name?.replace(/-/g, " ");
  const { data: course_id, isLoading: isCourseLoading } =
    useGetCourseExists(course_name_stripped);

  const { data: userExists, isLoading: isUserLoading } = useGetAuth();
  const { data: userAlreadyEnrolled, isLoading: isUserEnrolledLoading } =
    useGetCourseUserEnrolled(course_name);

  if (course_id === undefined && !isCourseLoading && !isUserEnrolledLoading) {
    return <NotFoundPage message="Looks like that course doesn't exist" />;
  }

  if (userAlreadyEnrolled && course_id !== undefined) {
    navigate(`/course/${course_id}`);
  }

  if (isUserLoading) {
    return <LoadingLayout />;
  }

  const handleEnrolOrSignUp = () => {
    // User is enrolling, but already has an account
    if (userExists) {
      return (
        <DashboardLayout isAuthPage titleBar={<></>} sideBar={<EnrolLayout />}>
          <div className={classes.mainContentContainer}>
            <Typography variant="body1" fullWidth textAlign="center">
              Beat procrastination with engaging, interactive study sessions on
              Study Ascent.
            </Typography>
            <img
              src={require("../../assets/images/study_ascent_bento.png")}
              alt=""
            />
          </div>
        </DashboardLayout>
      );
    }

    // User does not have an account
    return (
      <DashboardLayout isAuthPage titleBar={<></>} sideBar={<SignUpLayout />}>
        <div className={classes.mainContentContainer}>
          <Typography variant="body1" fullWidth textAlign="center">
            Beat procrastination with engaging, interactive study sessions on
            Study Ascent.
          </Typography>
          <img
            src={require("../../assets/images/study_ascent_bento.png")}
            alt=""
          />
        </div>
      </DashboardLayout>
    );
  };

  return (
    <RootLayout>
      {isCourseLoading || isUserLoading ? <></> : handleEnrolOrSignUp()}
    </RootLayout>
  );
};

import { useQuery } from "@tanstack/react-query";
import { QuizQuestion } from "../../typed";
import { getQuizQuestions } from "../../api";

export const useGetQuizQuestions = (
  course_id: number | undefined,
  module_id: number | undefined,
  topic_id: number | undefined
) => {
  return useQuery<QuizQuestion[] | null, Error>({
    queryKey: ["quizQuestions", course_id, module_id, topic_id],
    queryFn: () => getQuizQuestions(course_id, module_id, topic_id),
    enabled:
      course_id !== undefined &&
      module_id !== undefined &&
      topic_id !== undefined,
  });
};

import { useQuery } from "@tanstack/react-query";
import { getCourseUserEnrolled } from "../../api";

export const useGetCourseUserEnrolled = (course_name: string | undefined) => {
  return useQuery<boolean | undefined, Error>({
    queryKey: ["course_enrolled", course_name],
    queryFn: () => getCourseUserEnrolled(course_name),
    enabled: course_name !== undefined,
  });
};

import React from "react";
import { DashboardLayout, RootLayout } from "../../layouts";
import {
  CourseContainer,
  CourseRow,
  CourseSidebar,
  IconButton,
  Skeleton,
  Typography,
} from "../../components";
import { Divider } from "../../components/Divider/Divider";
import classes from "./CoursePage.module.scss";
import { ReactComponent as CourseIcon } from "../../assets/icons/course.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow_left.svg";

import { useNavigate, useParams } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { useGetCourse, useGetCourses } from "../../hooks";
import { Module } from "../../typed";

export const CoursePage = () => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const handleOnClickBack = () => {
    navigate("/");
  };
  const { course_id } = useParams();
  const course_id_number = course_id ? parseInt(course_id) : undefined;
  const { data: courses } = useGetCourses();
  const { data: modules, isLoading } = useGetCourse(course_id_number);

  const getFirstOpenRow = (modules: Module[]) => {
    // return the index of the first module where progress is < number of topics
    for (let i = 0; i < modules.length; i++) {
      if (
        modules[i].progress < parseInt(modules[i].number_of_topics.toString())
      ) {
        return i;
      }
    }
  };

  const getCourseTitle = () => {
    // get course title from course_id
    if (courses) {
      const course = courses.find(
        (course) => course.course_id === course_id_number
      );
      if (course) {
        return course.course_name;
      }
    }
    return "";
  };

  const getCourseLastStudied = () => {
    // get course last studied from course_id
    if (courses) {
      const course = courses.find(
        (course) => course.course_id === course_id_number
      );
      if (course) {
        return course.last_studied;
      }
    }
    return "-";
  };

  const last_studied = getCourseLastStudied();

  return (
    <RootLayout>
      <DashboardLayout
        sideBar={<CourseSidebar />}
        titleBar={
          breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md" ? (
            <div className={classes.titleBarSm}>
              <IconButton
                icon={<ArrowLeftIcon />}
                onclick={handleOnClickBack}
              />
              <div className={classes.titleBarSmTitle}>
                <Typography variant="h1">{getCourseTitle()}</Typography>
                <Typography>
                  {last_studied
                    ? `Last Studied: ${last_studied}`
                    : "New Course"}
                </Typography>
              </div>
            </div>
          ) : (
            <>
              <Typography variant="h1">{getCourseTitle()}</Typography>
              <Typography>
                {last_studied ? `Last Studied: ${last_studied}` : "New Course"}
              </Typography>
              <Divider />
              <div className={classes.backButtonContainer}>
                <IconButton
                  icon={<ArrowLeftIcon />}
                  onclick={handleOnClickBack}
                />
              </div>
            </>
          )
        }
      >
        <CourseContainer>
          <div className={classes.cardHeader}>
            <div className={classes.titleIcon}>
              <CourseIcon />
              <Typography variant="h2">Course Modules</Typography>
            </div>
            {breakpoint !== "xs" && (
              <Typography variant="h2">Progress</Typography>
            )}
          </div>
          <Divider disableMargin />
          {isLoading ? (
            Array(12)
              .fill(1)
              .map((_, index) => (
                <div key={index} className={classes.loadingCourseRow}>
                  <div className={classes.loadingCourseRowContent}>
                    <Skeleton />
                  </div>
                </div>
              ))
          ) : modules !== undefined ? (
            modules.map((module, index) => (
              <CourseRow
                course_id={course_id_number}
                module_id={module.module_id}
                key={module.module_id}
                title={module.module_name}
                progress={module.progress}
                numberOfTopics={module.number_of_topics}
                initialOpenState={index === getFirstOpenRow(modules)}
              />
            ))
          ) : (
            <div className={classes.errorContainer}>
              <Typography
                variant="body1"
                fullWidth
                textAlign="center"
                primary={false}
              >
                Unable to load course modules. Please try again later
              </Typography>
            </div>
          )}

          <div className={classes.cardFooter} />
        </CourseContainer>
      </DashboardLayout>
    </RootLayout>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { Topic } from "../../typed";

/**
 * Get Module Topics by Course and Module ID:
 * @param course_id
 * @param module_id
 * @returns ModuleTopics[] | null
 */
export const getCourseModuleTopics = async (
  course_id: number | undefined,
  module_id: number | undefined
): Promise<Topic[] | null> => {
  if (!course_id || !module_id) {
    return [];
  }

  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/courses/${course_id}/module/${module_id}/topics`
    );
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

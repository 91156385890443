import React from "react";
import { RootLayout } from "../../layouts";
import classes from "./DataCollectionPage.module.scss";
import { Card, Divider, Typography } from "../../components";

export const DataCollectionPage = () => {
  return (
    <RootLayout>
      <div className={classes.bodyContainer}>
        <div className={classes.contentContainer}>
          <Card>
            <Typography variant="h2" fullWidth>
              Study Ascent - Research Data Collection
            </Typography>
            <Divider onCard />
            <Typography>
              Thank you for using Study Ascent in your unit of study. Study
              Ascent has been created as part of a University of Sydney thesis
              project. The platform is designed to help students learn and
              revise course content in an engaging and interactive way.
              <br />
              <br />
              To assess the effectiveness of Study Ascent, anonymous
              (non-identifiable) data is collected on how you use the platform.
              This data is used to evaluate the platform and improve its
              effectiveness. Whether you use Study Ascent or not has no impact
              on your grades or your relationship with the research team and the
              University of Sydney. Although you may find it helpful in revising
              and learning the content within this course.
            </Typography>
            <Divider onCard />
            <Typography fullWidth>
              <strong>The data collected includes the following:</strong>
            </Typography>
            <Divider onCard />
            <ul>
              <li>
                Username: The anonymous randomly generated username you use to
                sign up
              </li>
              <li>
                Sign-up and Log-in dates: A list of dates you use the platform
              </li>
            </ul>
            <Divider onCard />
            <Typography fullWidth>For each course you enrol in:</Typography>
            <ul>
              <li>The date you last studied the course on the platform</li>
              <li>
                Your score (points) in the course to go on the leaderboard
              </li>
              <li>Your progression through the course (modules completed)</li>
            </ul>
            <Divider onCard />
            <Typography fullWidth>
              For each topic in the courses you are enrolled in:
            </Typography>
            <ul>
              <li>Your best score in the topic</li>
              <li>The number of attempts you make at the topic</li>
              <li>The number of questions you have completed in the topic</li>
              <li>
                The questions you flag as poor (not associated to your user)
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </RootLayout>
  );
};

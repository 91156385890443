import React, { useRef, useState } from "react";
import { RootLayout } from "../../layouts";
import { QuizLayout } from "../../layouts/QuizLayout/QuizLayout";
import {
  Button,
  Modal,
  QuizBodyCard,
  QuizDialogueCard,
  Typography,
} from "../../components";
import classes from "./QuizPage.module.scss";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right.svg";
import { QuizProvider, useQuizContext } from "../../contexts/QuizContext";
import getQuestionContentTyped from "../../utils/getQuestionContentTyped";
import {
  ActiveMissingWord,
  AnswerResponse,
  CorrectMistake,
  MultipleChoice,
  PassiveMissingWord,
  ShortAnswer,
} from "../../typed";
import { useNavigate, useParams } from "react-router-dom";
import { postQuizComplete, postQuizMarkAnswer } from "../../api";
import { useQueryClient } from "@tanstack/react-query";

const QuizPageContent = () => {
  const {
    setAnswerExplanation,
    setIsAnswerCorrect,
    isShowAnswer,
    setIsShowAnswer,
    setSubmitError,
    isActionDisabled,
    setIsActionDisabled,
    isLoading,
    answers,
    setAnswers,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    startTimer,
    stopTimer,
    quizQuestions,
    currentAnswerValue,
    setCurrentAnswerValue,
  } = useQuizContext();

  const rootScrollContainerRef = useRef<HTMLDivElement>(null);
  const { course_id, module_id, topic_id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getActionButtonText = () => {
    if (isLoading) {
      return "Loading";
    } else if (currentQuestionIndex === -1) {
      return "Begin";
    } else if (answers[currentQuestionIndex] === 2) {
      // Question flagged, show next
      return "Next";
    } else if (currentQuestionIndex === 9 && isShowAnswer) {
      if (isActionDisabled) {
        return "Marking...";
      }
      return "Complete";
    }
    if (isShowAnswer) {
      return "Next";
    }
    return "Submit";
  };

  const markAnswer = async (): Promise<boolean | undefined> => {
    const currentQuestionId = quizQuestions?.[currentQuestionIndex].question_id;

    // get current question
    const currentQuestion = quizQuestions?.[currentQuestionIndex];
    if (currentQuestion !== undefined) {
      const currentQuestionTyped = getQuestionContentTyped(currentQuestion);

      switch (currentQuestion.type) {
        case "MultipleChoice":
          const mcQuestion = currentQuestionTyped as MultipleChoice;
          setAnswerExplanation(
            `The correct answer was ${
              mcQuestion.options[mcQuestion.answer_index]
            }.`
          );
          return mcQuestion.answer_index === currentAnswerValue;

        case "ActiveMissingWord":
        case "PassiveMissingWord":
          const amwQuestion = currentQuestionTyped as ActiveMissingWord;
          const pmwQuestion = currentQuestionTyped as PassiveMissingWord;
          const missingValue =
            currentQuestion.type === "ActiveMissingWord"
              ? amwQuestion.missing_value
              : pmwQuestion.missing_value;
          setAnswerExplanation(`The correct answer was ${missingValue}.`);

          return (
            missingValue.trim().toLowerCase() ===
            String(currentAnswerValue).trim().toLowerCase()
          );

        case "CorrectMistake":
          const cmQuestion = currentQuestionTyped as CorrectMistake;
          if (currentQuestionId === undefined) return true; // error

          try {
            const response: AnswerResponse | null = await postQuizMarkAnswer({
              question_type: "CorrectMistake",
              question: cmQuestion.question,
              answer: String(currentAnswerValue),
            });

            // handle unable to mark question
            if (response === null) {
              return undefined;
            }

            setAnswerExplanation(response?.explanation);
            return response?.correct ?? false;
          } catch (error) {
            console.error("Error posting answer:", error);
            return true;
          }

        case "ShortAnswer":
          const saQuestion = currentQuestionTyped as ShortAnswer;
          if (currentQuestionId === undefined) return true; // error

          try {
            const response: AnswerResponse | null = await postQuizMarkAnswer({
              question_type: "ShortAnswer",
              question: saQuestion.question,
              answer: String(currentAnswerValue),
            });

            // handle unable to mark question
            if (response === null) {
              return undefined;
            }

            setAnswerExplanation(response?.explanation);
            return response?.correct ?? false;
          } catch (error) {
            console.error("Error posting answer:", error);
            return true;
          }

        default:
          return false;
      }
    }

    return true;
  };

  const handleCompleteQuiz = async () => {
    setIsActionDisabled(true);
    try {
      const course_id_number = course_id ? parseInt(course_id) : 0;
      const module_id_number = module_id ? parseInt(module_id) : 0;
      const topic_id_number = topic_id ? parseInt(topic_id) : 0;
      const currentQuestionId =
        quizQuestions?.[currentQuestionIndex].question_id;

      let quizScore = 0;
      // score of 0 if all questions are flagged
      if (answers.filter((answer) => answer === 2).length < 10) {
        quizScore =
          answers.filter((answer) => answer === 1).length /
          (10 - answers.filter((answer) => answer === 2).length);
      }

      if (currentQuestionId === undefined)
        return new Error("currentQuestionId is undefined");
      const success = await postQuizComplete(
        course_id_number,
        module_id_number,
        topic_id_number,
        quizScore,
        Math.floor(quizScore * 100)
      );
      if (!success) {
        // handle error
        setSubmitError(true);
        setIsActionDisabled(false);
        setIsShowAnswer(false);
        return;
      }
      // Show summary, reset inputted answer to undefined, Move to next question
      setIsAnswerCorrect(undefined);
      setIsShowAnswer(false);
      setCurrentAnswerValue(undefined);
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      //invalidate / reset quiz status & leaderboard
      queryClient.invalidateQueries({
        queryKey: ["leaderboard"],
      });
      queryClient.invalidateQueries({
        queryKey: ["topics"],
      });
      queryClient.invalidateQueries({
        queryKey: ["quizQuestions"],
      });
      // without reset it does not clear cached data
      queryClient.resetQueries({ queryKey: ["leaderboard"] });
      queryClient.resetQueries({ queryKey: ["topics"] });
      queryClient.resetQueries({ queryKey: ["quizQuestions"] });
    } catch (error) {
      // handle error
      setSubmitError(true);
      setIsActionDisabled(false);
      setIsShowAnswer(false);
      return;
    }
    setIsActionDisabled(false);
  };

  const handleOnClickSubmitAnswer = async () => {
    if (
      currentQuestionIndex !== -1 &&
      currentQuestionIndex < answers.length &&
      answers[currentQuestionIndex] === 2
    ) {
      // Question flagged, show next
      setCurrentAnswerValue(undefined);
      setIsAnswerCorrect(undefined);
      setIsShowAnswer(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      if (currentQuestionIndex === 9) {
        // stop quiz timer
        stopTimer();
        handleCompleteQuiz();
      }
      return;
    }

    if (rootScrollContainerRef.current) {
      rootScrollContainerRef.current.scrollTo(0, 0); // Scroll mainContainer to top
    }
    if (isShowAnswer !== true && currentQuestionIndex !== -1) {
      // Show explanation / correct page
      setIsActionDisabled(true);
      setIsShowAnswer(true);
      setIsAnswerCorrect(undefined);

      const isCorrect = await markAnswer();

      if (isCorrect === undefined) {
        setSubmitError(true);
        setIsShowAnswer(false);
        setIsActionDisabled(false);
        return;
      }
      setSubmitError(false);

      setAnswers((prevAnswers) => {
        const newAnswers = [...prevAnswers];
        newAnswers[currentQuestionIndex] = isCorrect ? 1 : 0;
        return newAnswers;
      });
      setIsAnswerCorrect(isCorrect);

      if (currentQuestionIndex === 9) {
        // stop quiz timer
        stopTimer();
      }
      setIsActionDisabled(false);
      return;
    } else {
      if (currentQuestionIndex === -1) {
        // start quiz timer
        startTimer();
      }

      if (currentQuestionIndex === 9) {
        handleCompleteQuiz();
      } else {
        // Show summary, reset inputted answer to undefined, Move to next question
        setIsAnswerCorrect(undefined);
        setIsShowAnswer(false);
        setCurrentAnswerValue(undefined);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }
  };

  const handleOnClickBack = () => {
    // If the user has not started the quiz, or completed the quiz
    // immediately navigate back
    if (currentQuestionIndex === -1 || currentQuestionIndex === 10) {
      handleNavigateBack();
    } else {
      // If the user has started the quiz, show a modal to confirm
      setModalOpen(true);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <RootLayout mainContainerRef={rootScrollContainerRef}>
      <QuizLayout
        onClickBack={handleOnClickBack}
        dialogBox={
          currentQuestionIndex >= 10 ? undefined : <QuizDialogueCard />
        }
        actionButton={
          currentQuestionIndex >= 10 ? undefined : (
            <Button
              icon={!isLoading && !isActionDisabled && <ArrowRightIcon />}
              onClick={handleOnClickSubmitAnswer}
              disabled={
                isLoading || isActionDisabled || quizQuestions?.length !== 10
              }
            >
              {getActionButtonText()}
            </Button>
          )
        }
      >
        <div className={classes.mainContentContainer}>
          <QuizBodyCard />
        </div>
      </QuizLayout>
      <Modal
        title={"Are you sure you want to leave?"}
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        onConfirm={handleNavigateBack}
      >
        <Typography>You will lose your progress if you leave now</Typography>
      </Modal>
    </RootLayout>
  );
};

export const QuizPage = () => (
  <QuizProvider>
    <QuizPageContent />
  </QuizProvider>
);

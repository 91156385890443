import React, { FC } from "react";
import classes from "./Divider.module.scss";

interface DividerProps {
  onCard?: boolean;
  disableMargin?: boolean;
}

export const Divider: FC<DividerProps> = ({
  onCard = false,
  disableMargin = false,
}) => {
  return (
    <div
      className={[
        classes.divider,
        onCard && classes.card,
        disableMargin && classes.noMargin,
      ].join(" ")}
    ></div>
  );
};

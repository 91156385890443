import React, { FC } from "react";
import classes from "./TextArea.module.scss";

interface TextAreaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  isQuiz?: boolean;
  disabled?: boolean;
}

export const TextArea: FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  maxLength,
  rows,
  isQuiz,
  disabled,
}) => {
  return (
    <textarea
      className={[
        classes.textArea,
        isQuiz && classes.quiz,
        rows === 1 && classes.paddingSm,
      ].join(" ")}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
      maxLength={maxLength}
      rows={rows}
      disabled={disabled}
    ></textarea>
  );
};

import React, { useState } from "react";
import { Divider, MCQOption, TextArea, Typography } from "../../";
import classes from "./QuizMCQ.module.scss";
import { useQuizContext } from "../../../contexts/QuizContext";
import { MultipleChoice } from "../../../typed";

export const QuizMCQ = () => {
  const {
    quizQuestions,
    currentQuestionIndex,
    isShowAnswer,
    setCurrentAnswerValue,
  } = useQuizContext();
  const currentQuestion = quizQuestions?.[currentQuestionIndex]
    .content as MultipleChoice;
  const [selectedAnswer, setSelectedAnswer] = useState<number>(-1);
  const [justification, setJustification] = useState<string>("");

  const handleOnClickOption = (index: number) => {
    if (index !== selectedAnswer) {
      setSelectedAnswer(index);
      setCurrentAnswerValue(index);
    } else {
      setSelectedAnswer(-1);
      setCurrentAnswerValue(undefined);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.optionsContainer}>
        {currentQuestion.options?.map((option, index) => (
          <MCQOption
            key={index}
            onClick={() => handleOnClickOption(index)}
            text={option}
            selected={selectedAnswer === index}
            disabled={isShowAnswer}
          />
        ))}
      </div>
      <Divider />
      <div className={classes.justificationContainer}>
        <Typography
          variant="body1"
          tooltip="Self-explanation is a proven study method that improves long-term retention"
        >
          Why do you think your answer is correct?
        </Typography>
        <Typography primary={false}>{justification.length} / 150</Typography>
      </div>
      <TextArea
        placeholder="Type your response"
        value={justification}
        onChange={(e) => setJustification(e.target.value)}
        maxLength={150}
        isQuiz
        disabled={isShowAnswer}
      ></TextArea>
    </div>
  );
};

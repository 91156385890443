import { useEffect, useState } from "react";
import { breakpoints } from "../constants/breakpoints";

export const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<string>("xxl");
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 1728,
    height: 1117,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    if (0 < windowSize.width && windowSize.width < 576) {
      setBreakPoint(breakpoints[0]);
    }
    if (576 < windowSize.width && windowSize.width < 768) {
      setBreakPoint(breakpoints[576]);
    }
    if (768 < windowSize.width && windowSize.width < 1200) {
      setBreakPoint(breakpoints[768]);
    }
    if (1200 < windowSize.width && windowSize.width < 1550) {
      setBreakPoint(breakpoints[1200]);
    }
    if (windowSize.width >= 1550) {
      setBreakPoint(breakpoints[1920]);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize.width]);
  return breakpoint;
};

import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postFeedback } from "../../api";

import classes from "./SurveyLayout.module.scss";
import {
  Divider,
  Modal,
  MultipleChoiceGroup,
  TextArea,
  Typography,
} from "../../components";
import { useBreakpoint } from "../../hooks/useBreakpoint";

export const SurveyLayout = () => {
  const queryClient = useQueryClient();
  const breakpoint = useBreakpoint();
  const [isOpen, setIsOpen] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Submit Answers");

  // MCQ Answers
  const [effectiveAnswer, setEffectiveAnswer] = useState(-1);
  const [aiAnswer, setAiAnswer] = useState(-1);
  const [engagingAnswer, setEngagingAnswer] = useState(-1);
  const [frequencyAnswer, setFrequencyAnswer] = useState(-1);
  const [likelyAnswer, setLikelyAnswer] = useState(-1);
  // Short Answers
  const [suggestionsAnswer, setSuggestionsAnswer] = useState("");
  const [benefitsAnswer, setBenefitsAnswer] = useState("");
  const [problemsAnswer, setProblemsAnswer] = useState("");

  const handleSubmitSurvey = async () => {
    try {
      await postFeedback(
        effectiveAnswer,
        aiAnswer,
        engagingAnswer,
        frequencyAnswer,
        likelyAnswer,
        suggestionsAnswer,
        benefitsAnswer,
        problemsAnswer
      );
      // Invalidate the feedback query to refetch the data
      setSubmitButtonText("Submitting...");
      setSubmitting(true);
      await queryClient.invalidateQueries({ queryKey: ["feedbackGiven"] });
      setSubmitButtonText("Submitted!");
      setSubmitting(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setSubmitButtonText("Submit Answers");
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (
      effectiveAnswer !== -1 &&
      aiAnswer !== -1 &&
      engagingAnswer !== -1 &&
      frequencyAnswer !== -1 &&
      likelyAnswer !== -1 &&
      suggestionsAnswer.length > 0 &&
      benefitsAnswer.length > 0 &&
      problemsAnswer.length > 0
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [
    effectiveAnswer,
    aiAnswer,
    engagingAnswer,
    frequencyAnswer,
    likelyAnswer,
    suggestionsAnswer,
    benefitsAnswer,
    problemsAnswer,
  ]);

  return (
    <Modal
      onConfirm={handleSubmitSurvey}
      closeOnBackdropClick={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      confirmDisabled={submitDisabled || submitting}
      cancellable={false}
      confirmText={submitButtonText}
    >
      <div className={classes.cardContentContainer}>
        <div>
          <div className={classes.cardTitleContainer}>
            {breakpoint !== "xs" && breakpoint !== "sm" && (
              <div className={classes.characterImageContainer}>
                <img
                  alt={"happy dinosaur character thumbs up"}
                  src={require("../../assets/images/character_dino_correct.png")}
                />
              </div>
            )}
            <div
              className={[
                classes.titleTextContainer,
                (breakpoint === "xs" || breakpoint === "sm") && classes.titleSm,
              ].join(" ")}
            >
              <Typography variant="h2">
                Thank you for using Study Ascent!
              </Typography>
              <Typography>
                Study Ascent has been created as a research project at the
                University of Sydney.
                <br />
                <br />
                Please help us by answering the following questions to continue
                using the platform.
              </Typography>
            </div>
          </div>
          <div className={classes.scrollText}>
            <Typography primary={false} fullWidth>
              Scroll down to continue
            </Typography>
          </div>
          <Divider onCard />
        </div>

        <div className={classes.questionContainer}>
          <Typography variant="body1">
            <strong>
              Compared to other study tools you have used, how effective is
              Study Ascent in helping you learn?
            </strong>
            <br />
            <br />
            Examples of other study tools: Lecture Notes, Flash Cards,
            Highlighting, Note Taking, etc. 'Effectiveness' can be defined as
            your ability to retain and apply study material.
          </Typography>
          <MultipleChoiceGroup
            selected={effectiveAnswer}
            setSelected={setEffectiveAnswer}
            options={[
              "Significantly more effective",
              "More effective",
              "Equivalently effective",
              "Slightly less effective",
              "Significantly less effective",
            ]}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1">
            <strong>
              Compared to ChatGPT or other AI Models, how effective is Study
              Ascent in helping you learn?
            </strong>
          </Typography>
          <MultipleChoiceGroup
            selected={aiAnswer}
            setSelected={setAiAnswer}
            options={[
              "Significantly more effective",
              "More effective",
              "Equivalently effective",
              "Slightly less effective",
              "Significantly less effective",
              "I don't use AI models to study",
            ]}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1">
            <strong>
              Compared to other study tools you have used, how engaging did you
              find utilising Study Ascent?
            </strong>
            <br />
            <br />
            'Engaging' can be defined as how motivated you feel to learn, your
            enjoyment, and being able to stay focused.
          </Typography>
          <MultipleChoiceGroup
            selected={engagingAnswer}
            setSelected={setEngagingAnswer}
            options={[
              "Significantly more engaging",
              "More engaging",
              "Equivalently engaging",
              "Slightly less engaging",
              "Significantly less engaging",
            ]}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1">
            <strong>
              Compared to other study tools you have used, how frequently did
              you use Study Ascent?
            </strong>
            <br />
            <br />
            'Frequency' can be defined as how often you utilised the platform
            compared to how often you use alternative study methods.
          </Typography>
          <MultipleChoiceGroup
            selected={frequencyAnswer}
            setSelected={setFrequencyAnswer}
            options={[
              "I used Study Ascent significantly more often",
              "I used Study Ascent slightly more often",
              "I used Study Ascent about the same amount",
              "I used Study Ascent less often",
              "I used Study Ascent significantly less often",
            ]}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1">
            <strong>
              If Study Ascent was available in your other units of study, how
              likely would you be to use it in those units?
            </strong>
          </Typography>
          <MultipleChoiceGroup
            selected={likelyAnswer}
            setSelected={setLikelyAnswer}
            options={[
              "Extremely likely",
              "Somewhat likely",
              "Neither likely nor unlikely",
              "Somewhat unlikely",
              "Extremely unlikely",
            ]}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1" fullWidth>
            <strong>
              What suggestions and improvements for Study Ascent do you have to
              improve your learning experience when using the platform?
            </strong>
          </Typography>
          <TextArea
            value={suggestionsAnswer}
            onChange={(e) => setSuggestionsAnswer(e.target.value)}
            placeholder="Type your response"
            rows={3}
            maxLength={500}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1" fullWidth>
            <strong>
              What benefits have you experienced from using Study Ascent in your
              course(s)?{" "}
            </strong>
          </Typography>
          <TextArea
            value={benefitsAnswer}
            onChange={(e) => setBenefitsAnswer(e.target.value)}
            placeholder="Type your response"
            rows={3}
            maxLength={500}
          />
        </div>
        <Divider onCard />

        <div className={classes.questionContainer}>
          <Typography variant="body1" fullWidth>
            <strong>
              What problems, if any, did you experience when using Study Ascent
              that negatively impacted your experience of the platform?{" "}
            </strong>
          </Typography>
          <TextArea
            value={problemsAnswer}
            onChange={(e) => setProblemsAnswer(e.target.value)}
            placeholder="Type your response"
            rows={3}
            maxLength={500}
          />
        </div>
        <Divider onCard />
        <Typography>
          {submitDisabled
            ? "Please answer all questions to be able to submit."
            : "Thank you for your feedback. We hope you have enjoyed using Study Ascent!"}
        </Typography>
        <div className={classes.bottomPadding} />
      </div>
    </Modal>
  );
};

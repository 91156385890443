import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { Module } from "../../typed";

/**
 * Get Course (Modules) by ID:
 * @param course_id
 * @returns Module[]
 */
export const getCourse = async (
  course_id: number | undefined
): Promise<Module[] | undefined> => {
  if (!course_id) {
    return [];
  }

  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/courses/${course_id}`
    );
    if (status === 200) {
      return data;
    } else {
      return [];
    }
  } catch (error) {
    return undefined;
  }
};

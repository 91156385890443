import React, { useEffect, useState } from "react";
import { Divider, TextArea, Typography } from "../../";
import { useQuizContext } from "../../../contexts";
import { CorrectMistake } from "../../../typed";
import classes from "./QuizCorrectMistake.module.scss";

export const QuizCorrectMistake = () => {
  const [input, setInput] = useState("");
  const {
    quizQuestions,
    currentQuestionIndex,
    isShowAnswer,
    setCurrentAnswerValue,
  } = useQuizContext();
  const currentQuestion = quizQuestions?.[currentQuestionIndex]
    .content as CorrectMistake;

  useEffect(() => {
    // Update the current answer value in the context
    setCurrentAnswerValue(input);
  }, [input, setCurrentAnswerValue]);

  return (
    <div className={classes.container}>
      <div className={classes.optionsContainer}>
        <Typography variant="body1">{currentQuestion.question}</Typography>
      </div>
      <Divider />
      <div className={classes.justificationContainer}>
        <Typography
          variant="body1"
          tooltip="Find and explain the mistake(s) in the above notes."
        >
          What do you think is wrong with Dino's notes?
        </Typography>
        <Typography primary={false}>{input.length} / 150</Typography>
      </div>
      <TextArea
        rows={3}
        placeholder="Type your response"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        maxLength={150}
        isQuiz
        disabled={isShowAnswer}
      ></TextArea>
    </div>
  );
};

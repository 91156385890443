import React, { FC } from "react";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { Card } from "../Card/Card";
import classes from "./CourseContainer.module.scss";

interface CourseContainerProps {
  children: React.ReactNode;
}

export const CourseContainer: FC<CourseContainerProps> = ({ children }) => {
  const breakpoint = useBreakpoint();

  if (breakpoint === "xs") {
    return <div className={classes.mobileContainer}>{children}</div>;
  } else {
    return <Card disablePadding>{children}</Card>;
  }
};

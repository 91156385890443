import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { AnswerResponse } from "../../typed";

/**
 * Post Quiz Question Answer:
 * @param course_id
 * @param module_id
 * @param topic_id
 * @param question_id
 * @param answer
 * @returns AnswerResponse { correct: boolean, explanation: string | undefined } | null
 */
export const postQuizMarkAnswer = async (answer_data: {
  question_type: string;
  question: string;
  answer: string;
}): Promise<AnswerResponse | null> => {
  try {
    const { data, status } = await axios.post(
      `${apiConstants.base_url}/quiz/mark-answer`,
      answer_data
    );
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

import React, { FC } from "react";
import classes from "./Tooltip.module.scss";

interface TooltipProps {
  children: string;
}

export const Tooltip: FC<TooltipProps> = ({ children }) => {
  return <span className={classes.tooltip}>{children}</span>;
};

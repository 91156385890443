import React, { FC, useState } from "react";
import classes from "./DashboardLayout.module.scss";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { IconButton, Divider } from "../../components";
import { ReactComponent as SidebarExpandIcon } from "../../assets/icons/sidebar_expand.svg";
import { ReactComponent as SidebarCollapseIcon } from "../../assets/icons/sidebar_collapse.svg";
import { motion } from "framer-motion";

interface DashboardLayoutProps {
  titleBar: React.ReactNode;
  sideBar: React.ReactNode;
  mobilePadding?: boolean;
  isAuthPage?: boolean;
  children: React.ReactNode;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({
  titleBar,
  sideBar,
  mobilePadding = false,
  isAuthPage = false,
  children,
}) => {
  const breakpoint = useBreakpoint();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const handleOnClickSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const sidebarDrawerContainerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const sidebarDrawerVariants = {
    hidden: { x: "100%" },
    visible: { x: "0%" },
    exit: { x: "100%" },
  };

  return (
    <div className={classes.dashboardLayoutContainer}>
      {(((breakpoint === "xl" ||
        breakpoint === "xxl" ||
        breakpoint === "xxxl") &&
        isAuthPage) ||
        !isAuthPage) && (
        <div
          className={[
            classes.mainContent,
            breakpoint !== "xxxl" && classes.mainContentPadded,
            (breakpoint === "xs" ||
              breakpoint === "sm" ||
              breakpoint === "md") &&
              classes.mainContentPaddedSm,
            !mobilePadding && breakpoint === "xs" && classes.disablePadding,
          ].join(" ")}
        >
          <div
            className={[
              classes.titlebar,
              mobilePadding === false &&
                breakpoint === "xs" &&
                classes.mobilePadding,
            ].join(" ")}
          >
            {breakpoint === "xs" ||
            breakpoint === "sm" ||
            breakpoint === "md" ? (
              <>
                <div className={classes.titlebarSm}>
                  {titleBar}
                  <IconButton
                    onclick={handleOnClickSidebar}
                    icon={
                      sidebarExpanded ? (
                        <SidebarCollapseIcon />
                      ) : (
                        <SidebarExpandIcon />
                      )
                    }
                  />
                </div>
                <Divider />
              </>
            ) : (
              titleBar
            )}
          </div>

          <div className={classes.bodyContainer}>
            <div className={classes.bodyInnerContainer}>{children}</div>
          </div>
          {(breakpoint === "xs" ||
            breakpoint === "sm" ||
            breakpoint === "md") &&
            sidebarExpanded && (
              <motion.div
                className={classes.sidebarDrawerContainer}
                onClick={(e) => setSidebarExpanded(false)}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={sidebarDrawerContainerVariants}
                transition={{
                  x: { type: "spring", bounce: 0 },
                  duration: 0.1,
                }}
              >
                <motion.div
                  className={classes.sidebarDrawer}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={sidebarDrawerVariants}
                  transition={{
                    x: { type: "spring", bounce: 0 },
                    duration: 0.1,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className={classes.sidebarDrawerBackButtonContainer}>
                    <IconButton
                      icon={<SidebarCollapseIcon />}
                      onclick={() => setSidebarExpanded(false)}
                    />
                  </div>
                  {sideBar}
                </motion.div>
              </motion.div>
            )}
        </div>
      )}
      {(breakpoint === "xl" ||
        breakpoint === "xxl" ||
        breakpoint === "xxxl" ||
        isAuthPage) && (
        <div
          className={[classes.sidebar, isAuthPage && classes.auth].join(" ")}
        >
          {sideBar}
        </div>
      )}
    </div>
  );
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { QuizQuestion } from "../../typed";

/**
 * Get Quiz Questions:
 * @param course_id
 * @param module_id
 * @param topic_id
 * @returns QuizQuestion[] | null
 */
export const getQuizQuestions = async (
  course_id: number | undefined,
  module_id: number | undefined,
  topic_id: number | undefined
): Promise<QuizQuestion[] | null> => {
  if (!course_id || !module_id || !topic_id) {
    return null;
  }
  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/quiz/${course_id}/${module_id}/${topic_id}`
    );
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

import React, { FC } from "react";
import styles from "./CircularProgress.module.scss";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/checkmark.svg";

interface CircularProgressProps {
  progress?: number;
  size?: number;
  stroke?: number;
}

export const CircularProgress: FC<CircularProgressProps> = ({
  progress = 0,
  size = 34, // Default size set to 34px if not provided
  stroke = 4, // Default stroke set to 4px if not provided
}) => {
  const radius = size / 2 - 2; // Adjusting radius based on size
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - progress * circumference;

  return (
    <div
      style={{ position: "relative", width: `${size}px`, height: `${size}px` }}
    >
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={styles.colourProgressBackground}
          strokeWidth={stroke.toString()}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill={progress === 1 ? styles.colourProgressCompleted : "none"}
          stroke={styles.colourProgressCompleted}
          strokeWidth={stroke.toString()}
          strokeLinecap="round"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: offset,
            transition: "stroke-dashoffset 0.35s",
            transformOrigin: "center center",
            transform: "rotate(-90deg)", // to start the progress from the top
          }}
        />
      </svg>
      {progress === 1 && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CheckmarkIcon />
        </div>
      )}
    </div>
  );
};

export default CircularProgress;

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

export const postFeedback = async (
  effectiveness_answer: number,
  ai_answer: number,
  engaging_answer: number,
  frequency_answer: number,
  likeliness_answer: number,
  suggestions_answer: string,
  benefits_answer: string,
  problems_answer: string
): Promise<boolean | undefined> => {
  try {
    const { data, status } = await axios.post(
      `${apiConstants.base_url}/feedback`,
      {
        effectiveness_answer,
        ai_answer,
        engaging_answer,
        frequency_answer,
        likeliness_answer,
        suggestions_answer,
        benefits_answer,
        problems_answer,
      }
    );
    if (status === 201) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";
import { Course } from "../../typed";

/**
 * Get Courses:
 * @returns Course[] | null
 */
export const getCourses = async (): Promise<Course[] | undefined> => {
  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/courses`
    );
    if (status === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

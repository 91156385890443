import React, { FC, useEffect, useState } from "react";

interface RampNumberTextProps {
  targetNumber: number;
  duration?: number; // duration in seconds
}

/**
 * Component that increments a number from 0 to targetNumber over a given duration
 * @param targetNumber - The number to increment to
 * @param duration - The duration in seconds to increment to the target number
 */
export const RampNumberText: FC<RampNumberTextProps> = ({
  targetNumber,
  duration = 0.8,
}) => {
  const [currentNumber, setCurrentNumber] = useState<number>(0);

  useEffect(() => {
    const startTime = performance.now();
    const endTime = startTime + duration * 1000;
    const increment = targetNumber / (duration * 1000);

    const updateNumber = (currentTime: number) => {
      const timeElapsed = currentTime - startTime;
      const newNumber = Math.min(targetNumber, timeElapsed * increment);
      setCurrentNumber(Math.round(newNumber));

      if (currentTime < endTime) {
        requestAnimationFrame(updateNumber);
      }
    };

    requestAnimationFrame(updateNumber);

    return () => {
      setCurrentNumber(0); // Reset number on component unmount or targetNumber change
    };
  }, [targetNumber, duration]);

  return <>{currentNumber.toString()}</>;
};

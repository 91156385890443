import React, { useEffect, useState } from "react";
import { Typography, Divider, TextArea } from "../../";
import { useQuizContext } from "../../../contexts";
import { ActiveMissingWord } from "../../../typed";
import classes from "./QuizActiveMissingWord.module.scss";

export const QuizActiveMissingWord = () => {
  const [input, setInput] = useState("");
  const [displayedValue, setDisplayedValue] = useState("");
  const {
    quizQuestions,
    currentQuestionIndex,
    isShowAnswer,
    setCurrentAnswerValue,
  } = useQuizContext();
  const currentQuestion = quizQuestions?.[currentQuestionIndex]
    .content as ActiveMissingWord;

  useEffect(() => {
    const expected_length = currentQuestion.missing_value.length;

    if (input.length < expected_length) {
      let placeholderText = "";
      for (let i = 0; i < expected_length - input.length; i++) {
        if (currentQuestion.missing_value[input.length + i] === " ") {
          placeholderText += " ";
        } else {
          placeholderText += "_";
        }
      }
      setDisplayedValue(input + placeholderText);
    } else {
      setDisplayedValue(input);
    }
  }, [input, currentQuestion.missing_value]);

  useEffect(() => {
    // Update the current answer value in the context
    setCurrentAnswerValue(input);
  }, [input, setCurrentAnswerValue]);

  return (
    <div className={classes.container}>
      <div className={classes.optionsContainer}>
        <Typography variant="body1">
          {currentQuestion.sentence_parts[0]}
          {displayedValue}
          {currentQuestion.sentence_parts[1]}
        </Typography>
      </div>
      <Divider />
      <div className={classes.justificationContainer}>
        <Typography
          variant="body1"
          tooltip="Hint: Use the word length as a guide"
        >
          What do you think is missing?
        </Typography>
        <Typography primary={false}>
          {input.length} / {currentQuestion.missing_value.length}
        </Typography>
      </div>
      <TextArea
        rows={1}
        placeholder="Type the missing word(s)"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        maxLength={currentQuestion.missing_value.length}
        isQuiz
        disabled={isShowAnswer}
      ></TextArea>
    </div>
  );
};

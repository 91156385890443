import { useQuery } from "@tanstack/react-query";
import { LeaderboardItem } from "../../typed";
import { getCourseLeaderboard } from "../../api";

export const useGetCourseLeaderboard = (course_id: number | undefined) => {
  return useQuery<LeaderboardItem[], Error>({
    queryKey: ["leaderboard", course_id],
    queryFn: () => getCourseLeaderboard(course_id),
    enabled: course_id !== undefined,
  });
};

import axios from "axios";
import { apiConstants } from "../../constants/apiConstants";

/**
 * Get Topic Name:
 * @param course_id
 * @param module_id
 * @param topic_id
 * @returns string
 */
export const getTopicName = async (
  course_id: number | undefined,
  module_id: number | undefined,
  topic_id: number | undefined
): Promise<string | null> => {
  if (!course_id || !module_id || !topic_id) {
    return null;
  }
  try {
    const { data, status } = await axios.get(
      `${apiConstants.base_url}/courses/${course_id}/module/${module_id}/topic/${topic_id}/name`
    );
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
